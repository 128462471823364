import {
  CLEAR_PRODUCTS_DEFAULTS,
  DELETE_PRODUCTIMAGE_ERROR,
  DELETE_PRODUCTIMAGE_REQUEST,
  DELETE_PRODUCTIMAGE_SUCCESS,
  MANAGE_PRODUCTIMAGE_ERROR,
  MANAGE_PRODUCTIMAGE_REQUEST,
  MANAGE_PRODUCTIMAGE_SUCCESS,
  MANAGE_PRODUCTINFO_ERROR,
  MANAGE_PRODUCTINFO_REQUEST,
  MANAGE_PRODUCTINFO_SUCCESS,
  MANAGE_PRODUCT_ERROR,
  MANAGE_PRODUCT_REQUEST,
  MANAGE_PRODUCT_SUCCESS,
  PRODUCTIMAGE_LIST_ERROR,
  PRODUCTIMAGE_LIST_REQUEST,
  PRODUCTIMAGE_LIST_SUCCESS,
  PRODUCTINFO_ERROR,
  PRODUCTINFO_REQUEST,
  PRODUCTINFO_SUCCESS,
  PRODUCT_LIST_ERROR,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  UPDATE_PRODUCTSTATUS_ERROR,
  UPDATE_PRODUCTSTATUS_REQUEST,
  UPDATE_PRODUCTSTATUS_SUCCESS,
} from "../actions";

export const clearProductDefaults = (message) => ({
  type: CLEAR_PRODUCTS_DEFAULTS,
  payload: message,
});

export const manageProductRequest = (request) => ({
  payload: request,
  type: MANAGE_PRODUCT_REQUEST,
});
export const manageProductSuccess = (response) => ({
  payload: response,
  type: MANAGE_PRODUCT_SUCCESS,
});

export const manageProductError = (message) => ({
  payload: message,
  type: MANAGE_PRODUCT_ERROR,
});

export const productsListRequest = (request) => ({
  payload: request,
  type: PRODUCT_LIST_REQUEST,
});
export const productsListSuccess = (response) => ({
  payload: response,
  type: PRODUCT_LIST_SUCCESS,
});

export const productsListError = (message) => ({
  payload: message,
  type: PRODUCT_LIST_ERROR,
});

export const manageProductInfoRequest = (request) => ({
  payload: request,
  type: MANAGE_PRODUCTINFO_REQUEST,
});
export const manageProductInfoSuccess = (response) => ({
  payload: response,
  type: MANAGE_PRODUCTINFO_SUCCESS,
});

export const manageProductInfoError = (message) => ({
  payload: message,
  type: MANAGE_PRODUCTINFO_ERROR,
});

export const productInfoRequest = (request) => ({
  payload: request,
  type: PRODUCTINFO_REQUEST,
});
export const productInfoSuccess = (response) => ({
  payload: response,
  type: PRODUCTINFO_SUCCESS,
});

export const productInfoError = (message) => ({
  payload: message,
  type: PRODUCTINFO_ERROR,
});

export const manageProductImageRequest = (request) => ({
  payload: request,
  type: MANAGE_PRODUCTIMAGE_REQUEST,
});
export const manageProductImageSuccess = (response) => ({
  payload: response,
  type: MANAGE_PRODUCTIMAGE_SUCCESS,
});

export const manageProductImageError = (message) => ({
  payload: message,
  type: MANAGE_PRODUCTIMAGE_ERROR,
});

export const productImageListRequest = (request) => ({
  payload: request,
  type: PRODUCTIMAGE_LIST_REQUEST,
});
export const productImageListSuccess = (response) => ({
  payload: response,
  type: PRODUCTIMAGE_LIST_SUCCESS,
});

export const productImageListError = (message) => ({
  payload: message,
  type: PRODUCTIMAGE_LIST_ERROR,
});

export const deleteProductImageRequest = (request) => ({
  payload: request,
  type: DELETE_PRODUCTIMAGE_REQUEST,
});
export const deleteProductImageSuccess = (response) => ({
  payload: response,
  type: DELETE_PRODUCTIMAGE_SUCCESS,
});

export const deleteProductImageError = (message) => ({
  payload: message,
  type: DELETE_PRODUCTIMAGE_ERROR,
});

export const updateProductStatusRequest = (request) => ({
  payload: request,
  type: UPDATE_PRODUCTSTATUS_REQUEST,
});
export const updateProductStatusSuccess = (response) => ({
  payload: response,
  type: UPDATE_PRODUCTSTATUS_SUCCESS,
});

export const updateProductStatusError = (message) => ({
  payload: message,
  type: UPDATE_PRODUCTSTATUS_ERROR,
});
