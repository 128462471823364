import {
  CLEAR_ITEMS_DEFAULTS,
  DELETE_ITEMIMAGE_ERROR,
  DELETE_ITEMIMAGE_REQUEST,
  DELETE_ITEMIMAGE_SUCCESS,
  ITEMIMAGE_LIST_ERROR,
  ITEMIMAGE_LIST_REQUEST,
  ITEMIMAGE_LIST_SUCCESS,
  ITEMINFO_ERROR,
  ITEMINFO_REQUEST,
  ITEMINFO_SUCCESS,
  ITEM_ERROR,
  ITEM_LIST_ERROR,
  ITEM_LIST_REQUEST,
  ITEM_LIST_SUCCESS,
  ITEM_REQUEST,
  ITEM_SUCCESS,
  MANAGE_ITEMIMAGE_ERROR,
  MANAGE_ITEMIMAGE_REQUEST,
  MANAGE_ITEMIMAGE_SUCCESS,
  MANAGE_ITEMINFO_ERROR,
  MANAGE_ITEMINFO_REQUEST,
  MANAGE_ITEMINFO_SUCCESS,
  MANAGE_ITEM_ERROR,
  MANAGE_ITEM_REQUEST,
  MANAGE_ITEM_SUCCESS,
  UPDATE_ITEMSTATUS_ERROR,
  UPDATE_ITEMSTATUS_REQUEST,
  UPDATE_ITEMSTATUS_SUCCESS,
} from "../actions";

export const clearItemDefaults = (message) => ({
  type: CLEAR_ITEMS_DEFAULTS,
  payload: message,
});

export const manageItemRequest = (request) => ({
  payload: request,
  type: MANAGE_ITEM_REQUEST,
});
export const manageItemSuccess = (response) => ({
  payload: response,
  type: MANAGE_ITEM_SUCCESS,
});

export const manageItemError = (message) => ({
  payload: message,
  type: MANAGE_ITEM_ERROR,
});

export const ItemsListRequest = (request) => ({
  payload: request,
  type: ITEM_LIST_REQUEST,
});
export const ItemsListSuccess = (response) => ({
  payload: response,
  type: ITEM_LIST_SUCCESS,
});

export const ItemsListError = (message) => ({
  payload: message,
  type: ITEM_LIST_ERROR,
});

export const manageItemInfoRequest = (request) => ({
  payload: request,
  type: MANAGE_ITEMINFO_REQUEST,
});
export const manageItemInfoSuccess = (response) => ({
  payload: response,
  type: MANAGE_ITEMINFO_SUCCESS,
});

export const manageItemInfoError = (message) => ({
  payload: message,
  type: MANAGE_ITEMINFO_ERROR,
});

export const ItemInfoRequest = (request) => ({
  payload: request,
  type: ITEMINFO_REQUEST,
});
export const ItemInfoSuccess = (response) => ({
  payload: response,
  type: ITEMINFO_SUCCESS,
});

export const ItemInfoError = (message) => ({
  payload: message,
  type: ITEMINFO_ERROR,
});

export const manageItemImageRequest = (request) => ({
  payload: request,
  type: MANAGE_ITEMIMAGE_REQUEST,
});
export const manageItemImageSuccess = (response) => ({
  payload: response,
  type: MANAGE_ITEMIMAGE_SUCCESS,
});

export const manageItemImageError = (message) => ({
  payload: message,
  type: MANAGE_ITEMIMAGE_ERROR,
});

export const ItemImageListRequest = (request) => ({
  payload: request,
  type: ITEMIMAGE_LIST_REQUEST,
});
export const ItemImageListSuccess = (response) => ({
  payload: response,
  type: ITEMIMAGE_LIST_SUCCESS,
});

export const ItemImageListError = (message) => ({
  payload: message,
  type: ITEMIMAGE_LIST_ERROR,
});

export const deleteItemImageRequest = (request) => ({
  payload: request,
  type: DELETE_ITEMIMAGE_REQUEST,
});
export const deleteItemImageSuccess = (response) => ({
  payload: response,
  type: DELETE_ITEMIMAGE_SUCCESS,
});

export const deleteItemImageError = (message) => ({
  payload: message,
  type: DELETE_ITEMIMAGE_ERROR,
});

export const updateItemStatusRequest = (request) => ({
  payload: request,
  type: UPDATE_ITEMSTATUS_REQUEST,
});
export const updateItemStatusSuccess = (response) => ({
  payload: response,
  type: UPDATE_ITEMSTATUS_SUCCESS,
});

export const updateItemStatusError = (message) => ({
  payload: message,
  type: UPDATE_ITEMSTATUS_ERROR,
});

export const itemRequest = (request) => ({
  payload: request,
  type: ITEM_REQUEST,
});
export const itemSuccess = (response) => ({
  payload: response,
  type: ITEM_SUCCESS,
});

export const itemError = (message) => ({
  payload: message,
  type: ITEM_ERROR,
});
