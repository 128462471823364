import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";

// axios.defaults.baseURL = "http://localhost:2020/api/v1";
axios.defaults.baseURL =
  window.location.hostname == "localhost"
    ? "http://localhost:2020/api/v1"
    : "https://adminapi.ideapharmacy.in/api/v1";

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
