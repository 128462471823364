import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  GET,
  POST,
  LIST,
  PATCH,
  DELETE,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import { objectToQueryString } from "../../helpers/utils";
import {
  DELETE_ITEMIMAGE_REQUEST,
  ITEMIMAGE_LIST_REQUEST,
  ITEMINFO_REQUEST,
  ITEM_LIST_REQUEST,
  ITEM_REQUEST,
  MANAGE_ITEMIMAGE_REQUEST,
  MANAGE_ITEMINFO_REQUEST,
  MANAGE_ITEM_REQUEST,
  UPDATE_ITEMSTATUS_REQUEST,
} from "../actions";
import {
  deleteItemImageError,
  deleteItemImageSuccess,
  itemError,
  ItemImageListError,
  ItemImageListSuccess,
  ItemInfoError,
  ItemInfoSuccess,
  ItemsListError,
  ItemsListSuccess,
  itemSuccess,
  manageItemError,
  manageItemImageError,
  manageItemImageSuccess,
  manageItemInfoError,
  manageItemInfoSuccess,
  manageItemSuccess,
  updateItemStatusError,
  updateItemStatusSuccess,
} from "./action";
export function* watchManageItem() {
  yield takeLatest(MANAGE_ITEM_REQUEST, manageItem);
}
function* manageItem({ payload }) {
  let url = "/Items";
  try {
    const response = yield call(
      payload && payload.item_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageItemError(response.message));
    } else {
      yield put(manageItemSuccess(response));
    }
  } catch (ex) {
    yield put(manageItemError(ex));
  }
}

export function* watchItemList() {
  yield takeLatest(ITEM_LIST_REQUEST, ItemList);
}
function* ItemList({ payload }) {
  let url = `/items?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(ItemsListError(response.message));
    } else {
      yield put(ItemsListSuccess(response));
    }
  } catch (ex) {
    yield put(ItemsListError(ex));
  }
}

export function* watchItem() {
  yield takeLatest(ITEM_REQUEST, item);
}
function* item({ payload }) {
  let url = `/items/${payload.item_id}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(itemError(response.message));
    } else {
      yield put(itemSuccess(response));
    }
  } catch (ex) {
    yield put(itemError(ex));
  }
}

export function* watchManageItemInfo() {
  yield takeLatest(MANAGE_ITEMINFO_REQUEST, manageItemInfo);
}
function* manageItemInfo({ payload }) {
  let url = `/Items/${payload.item_id}/Iteminfo`;
  try {
    const response = yield call(
      payload && payload.info_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageItemInfoError(response.message));
    } else {
      yield put(manageItemInfoSuccess(response));
    }
  } catch (ex) {
    yield put(manageItemInfoError(ex));
  }
}

export function* watchItemInfo() {
  yield takeLatest(ITEMINFO_REQUEST, ItemInfo);
}
function* ItemInfo({ payload }) {
  let url = `/Items/${payload.item_id}/Iteminfo`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(ItemInfoError(response.message));
    } else {
      yield put(ItemInfoSuccess(response));
    }
  } catch (ex) {
    yield put(ItemInfoError(ex));
  }
}

export function* watchManageItemImage() {
  yield takeLatest(MANAGE_ITEMIMAGE_REQUEST, manageItemImage);
}
function* manageItemImage({ payload }) {
  let url = `/Items/${payload.item_id}/Itemimage`;
  try {
    const response = yield call(
      payload && payload.image_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageItemImageError(response.message));
    } else {
      yield put(manageItemImageSuccess(response));
    }
  } catch (ex) {
    yield put(manageItemImageError(ex));
  }
}
export function* watchItemImageList() {
  yield takeLatest(ITEMIMAGE_LIST_REQUEST, ItemImageList);
}
function* ItemImageList({ payload }) {
  let url = `/Items/${payload.item_id}/Itemimage?${objectToQueryString(
    payload
  )}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(ItemImageListError(response.message));
    } else {
      yield put(ItemImageListSuccess(response));
    }
  } catch (ex) {
    yield put(ItemImageListError(ex));
  }
}

export function* watchDeleteItemImage() {
  yield takeLatest(DELETE_ITEMIMAGE_REQUEST, deleteItemImage);
}
function* deleteItemImage({ payload }) {
  let url = `/Items/${payload.item_id}/Itemimage?${objectToQueryString(
    payload
  )}`;
  try {
    const response = yield call(DELETE, url, payload);
    if (response && response.is_error) {
      yield put(deleteItemImageError(response.message));
    } else {
      yield put(deleteItemImageSuccess(response));
    }
  } catch (ex) {
    yield put(deleteItemImageError(ex));
  }
}

export function* watchUpdateItemStatus() {
  yield takeLatest(UPDATE_ITEMSTATUS_REQUEST, updateItemStatus);
}
function* updateItemStatus({ payload }) {
  let url = `/Items`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateItemStatusError(response.message));
    } else {
      yield put(updateItemStatusSuccess(response));
    }
  } catch (ex) {
    yield put(updateItemStatusError(ex));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchManageItem),
    fork(watchItemList),
    fork(watchManageItemInfo),
    fork(watchItemInfo),
    fork(watchManageItemImage),
    fork(watchItemImageList),
    fork(watchDeleteItemImage),
    fork(watchItem),
    fork(watchUpdateItemStatus),
  ]);
}
