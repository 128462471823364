import {
  ADDRESS_LIST_ERROR,
  ADDRESS_LIST_REQUEST,
  ADDRESS_LIST_SUCCESS,
  CLEAR_USER_DEFAULTS,
  DELETE_ADDERSS_ERROR,
  DELETE_ADDERSS_REQUEST,
  DELETE_ADDERSS_SUCCESS,
  LOGIN_ERROR,
  LOGIN_MODAL_ERROR,
  LOGIN_MODAL_REQUEST,
  LOGIN_MODAL_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_MODAL_ERROR,
  SIGNUP_MODAL_REQUEST,
  SIGNUP_MODAL_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  USER_DETAILS_ERROR,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
} from "../actions";
export const clearUserDefaults = (message) => ({
  type: CLEAR_USER_DEFAULTS,
  payload: message,
});

export const loginRequest = (request, history) => ({
  payload: { request, history },
  type: LOGIN_REQUEST,
});
export const loginSuccess = (response) => ({
  payload: response,
  type: LOGIN_SUCCESS,
});

export const loginError = (message) => ({
  payload: message,
  type: LOGIN_ERROR,
});
export const userDetailsRequest = (request) => ({
  payload: request,
  type: USER_DETAILS_REQUEST,
});
export const userDetailsSuccess = (response) => ({
  payload: response,
  type: USER_DETAILS_SUCCESS,
});

export const userDetailsError = (message) => ({
  payload: message,
  type: USER_DETAILS_ERROR,
});

export const loginModalRequest = (request) => ({
  payload: request,
  type: LOGIN_MODAL_REQUEST,
});
export const loginModalSuccess = (response) => ({
  payload: response,
  type: LOGIN_MODAL_SUCCESS,
});

export const loginModalError = (message) => ({
  payload: message,
  type: LOGIN_MODAL_ERROR,
});
