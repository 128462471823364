import {
  BATCHNO_LIST_ERROR,
  BATCHNO_LIST_REQUEST,
  BATCHNO_LIST_SUCCESS,
  CANCEL_ITEM_ERROR,
  CANCEL_ITEM_REQUEST,
  CANCEL_ITEM_SUCCESS,
  CANCEL_ORDER_ERROR,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CHECK_PAYMENT_ERROR,
  CHECK_PAYMENT_REQUEST,
  CHECK_PAYMENT_SUCCESS,
  CLEAR_ORDER_DEFAULTS,
  DELETE_ITEM_ERROR,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ORDERITEM_ERROR,
  DELETE_ORDERITEM_REQUEST,
  DELETE_ORDERITEM_SUCCESS,
  ORDERITEM_HISTROY_ERROR,
  ORDERITEM_HISTROY_REQUEST,
  ORDERITEM_HISTROY_SUCCESS,
  DELIVER_INFO_ERROR,
  DELIVER_INFO_REQUEST,
  DELIVER_INFO_SUCCESS,
  MANAGE_BILL_ERROR,
  MANAGE_BILL_REQUEST,
  MANAGE_BILL_SUCCESS,
  MANAGE_ONLINE_DELIVER_ERROR,
  MANAGE_ONLINE_DELIVER_REQUEST,
  MANAGE_ONLINE_DELIVER_SUCCESS,
  MANAGE_ONLINE_ORDERSTATUS_ERROR,
  MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  MANAGE_ONLINE_ORDERSTATUS_SUCCESS,
  MANAGE_ORDERITEM_ERROR,
  MANAGE_ORDERITEM_REQUEST,
  MANAGE_ORDERITEM_SUCCESS,
  MANAGE_PHARMACY_ERROR,
  MANAGE_PHARMACY_REQUEST,
  MANAGE_PHARMACY_SUCCESS,
  MANAGE_REFUND_ERROR,
  MANAGE_REFUND_REQUEST,
  MANAGE_REFUND_SUCCESS,
  MANAGE_TEMP_ORDER_ERROR,
  MANAGE_TEMP_ORDER_REQUEST,
  MANAGE_TEMP_ORDER_SUCCESS,
  ORDERSTATUS_ERROR,
  ORDERSTATUS_REQUEST,
  ORDERSTATUS_SUCCESS,
  ORDER_ERROR,
  ORDER_HISTORY_ERROR,
  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_SUCCESS,
  ORDER_IMAGES_ERROR,
  ORDER_IMAGES_REQUEST,
  ORDER_IMAGES_SUCCESS,
  ORDER_INFO_ERROR,
  ORDER_INFO_REQUEST,
  ORDER_INFO_SUCCESS,
  ORDER_ITEMS_ERROR,
  ORDER_ITEMS_REQUEST,
  ORDER_ITEMS_SUCCESS,
  ORDER_LIST_ERROR,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  PHARMACYBILL_INFO_ERROR,
  PHARMACYBILL_INFO_REQUEST,
  PHARMACYBILL_INFO_SUCCESS,
  PHARMACY_GATEWAY_ERROR,
  PHARMACY_GATEWAY_REQUEST,
  PHARMACY_GATEWAY_SUCCESS,
  PRODUCT_GATEWAY_ERROR,
  PRODUCT_GATEWAY_REQUEST,
  PRODUCT_GATEWAY_SUCCESS,
  RETURNORDERS_ERROR,
  RETURNORDERS_REQUEST,
  RETURNORDERS_SUCCESS,
  RETURNORDER_INFO_ERROR,
  RETURNORDER_INFO_REQUEST,
  RETURNORDER_INFO_SUCCESS,
  RETURNORDER_ITEMS_ERROR,
  RETURNORDER_ITEMS_REQUEST,
  RETURNORDER_ITEMS_SUCCESS,
  UPDATE_ORDERITEMSTATUS_ERROR,
  UPDATE_ORDERITEMSTATUS_REQUEST,
  UPDATE_ORDERITEMSTATUS_SUCCESS,
} from "../actions";
export const clearOrderDefaults = (message) => ({
  type: CLEAR_ORDER_DEFAULTS,
  payload: message,
});

export const managePharmacyRequest = (request) => ({
  type: MANAGE_PHARMACY_REQUEST,
  payload: request,
});
export const managePharmacySuccess = (response) => ({
  type: MANAGE_PHARMACY_SUCCESS,
  payload: response,
});
export const managePharmacyError = (message) => ({
  type: MANAGE_PHARMACY_ERROR,
  payload: message,
});

export const orderListRequest = (request) => ({
  type: ORDER_LIST_REQUEST,
  payload: request,
});
export const orderListSuccess = (response) => ({
  type: ORDER_LIST_SUCCESS,
  payload: response,
});
export const orderListError = (message) => ({
  type: ORDER_LIST_ERROR,
  payload: message,
});

export const orderInfoRequest = (request) => ({
  type: ORDER_INFO_REQUEST,
  payload: request,
});
export const orderInfoSuccess = (response) => ({
  type: ORDER_INFO_SUCCESS,
  payload: response,
});
export const orderInfoError = (message) => ({
  type: ORDER_INFO_ERROR,
  payload: message,
});

export const pharmacyGatewayRequest = (request) => ({
  type: PHARMACY_GATEWAY_REQUEST,
  payload: request,
});
export const pharmacyGatewaySuccess = (response) => ({
  type: PHARMACY_GATEWAY_SUCCESS,
  payload: response,
});
export const pharmacyGatewayError = (message) => ({
  type: PHARMACY_GATEWAY_ERROR,
  payload: message,
});

export const productGatewayRequest = (request) => ({
  type: PRODUCT_GATEWAY_REQUEST,
  payload: request,
});
export const productGatewaySuccess = (response) => ({
  type: PRODUCT_GATEWAY_SUCCESS,
  payload: response,
});
export const productGatewayError = (message) => ({
  type: PRODUCT_GATEWAY_ERROR,
  payload: message,
});

export const checkPaymentRequest = (request) => ({
  type: CHECK_PAYMENT_REQUEST,
  payload: request,
});
export const checkPaymentSuccess = (response) => ({
  type: CHECK_PAYMENT_SUCCESS,
  payload: response,
});
export const checkPaymentError = (message) => ({
  type: CHECK_PAYMENT_ERROR,
  payload: message,
});

export const manageTempOrderRequest = (request) => ({
  type: MANAGE_TEMP_ORDER_REQUEST,
  payload: request,
});
export const manageTempOrderSuccess = (response) => ({
  type: MANAGE_TEMP_ORDER_SUCCESS,
  payload: response,
});
export const manageTempOrderError = (message) => ({
  type: MANAGE_TEMP_ORDER_ERROR,
  payload: message,
});

export const orderStatusRequest = (request) => ({
  type: ORDERSTATUS_REQUEST,
  payload: request,
});
export const orderStatusSuccess = (response) => ({
  type: ORDERSTATUS_SUCCESS,
  payload: response,
});
export const orderStatusError = (message) => ({
  type: ORDERSTATUS_ERROR,
  payload: message,
});

export const deliverInfoRequest = (request) => ({
  type: DELIVER_INFO_REQUEST,
  payload: request,
});
export const deliverInfoSuccess = (response) => ({
  type: DELIVER_INFO_SUCCESS,
  payload: response,
});
export const deliverInfoError = (message) => ({
  type: DELIVER_INFO_ERROR,
  payload: message,
});

export const pharmacyBillInfoRequest = (request) => ({
  type: PHARMACYBILL_INFO_REQUEST,
  payload: request,
});
export const pharmacyBillInfoSuccess = (response) => ({
  type: PHARMACYBILL_INFO_SUCCESS,
  payload: response,
});
export const pharmacyBillInfoError = (message) => ({
  type: PHARMACYBILL_INFO_ERROR,
  payload: message,
});

export const manageOnlineOrderStatusRequest = (request) => ({
  type: MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  payload: request,
});
export const manageOnlineOrderStatusSuccess = (response) => ({
  type: MANAGE_ONLINE_ORDERSTATUS_SUCCESS,
  payload: response,
});
export const manageOnlineOrderStatusError = (message) => ({
  type: MANAGE_ONLINE_ORDERSTATUS_ERROR,
  payload: message,
});

export const manageOnlineDeliverRequest = (request) => ({
  type: MANAGE_ONLINE_DELIVER_REQUEST,
  payload: request,
});
export const manageOnlineDeliverSuccess = (response) => ({
  type: MANAGE_ONLINE_DELIVER_SUCCESS,
  payload: response,
});
export const manageOnlineDeliverError = (message) => ({
  type: MANAGE_ONLINE_DELIVER_ERROR,
  payload: message,
});

export const updateOrderItemStatusRequest = (request) => ({
  type: UPDATE_ORDERITEMSTATUS_REQUEST,
  payload: request,
});
export const updateOrderItemStatusSuccess = (response) => ({
  type: UPDATE_ORDERITEMSTATUS_SUCCESS,
  payload: response,
});
export const updateOrderItemStatusError = (message) => ({
  type: UPDATE_ORDERITEMSTATUS_ERROR,
  payload: message,
});

export const manageBillRequest = (request) => ({
  type: MANAGE_BILL_REQUEST,
  payload: request,
});
export const manageBillSuccess = (response) => ({
  type: MANAGE_BILL_SUCCESS,
  payload: response,
});
export const manageBillError = (message) => ({
  type: MANAGE_BILL_ERROR,
  payload: message,
});

export const batchNoListRequest = (request) => ({
  type: BATCHNO_LIST_REQUEST,
  payload: request,
});
export const batchNoListSuccess = (response) => ({
  type: BATCHNO_LIST_SUCCESS,
  payload: response,
});
export const batchNoListError = (message) => ({
  type: BATCHNO_LIST_ERROR,
  payload: message,
});

export const returnOrdersRequest = (request) => ({
  type: RETURNORDERS_REQUEST,
  payload: request,
});
export const returnOrdersSuccess = (response) => ({
  type: RETURNORDERS_SUCCESS,
  payload: response,
});
export const returnOrdersError = (message) => ({
  type: RETURNORDERS_ERROR,
  payload: message,
});

export const returnOrderInfoRequest = (request) => ({
  type: RETURNORDER_INFO_REQUEST,
  payload: request,
});
export const returnOrderInfoSuccess = (response) => ({
  type: RETURNORDER_INFO_SUCCESS,
  payload: response,
});
export const returnOrderInfoError = (message) => ({
  type: RETURNORDER_INFO_ERROR,
  payload: message,
});

export const returnOrderItemsRequest = (request) => ({
  type: RETURNORDER_ITEMS_REQUEST,
  payload: request,
});
export const returnOrderItemsSuccess = (response) => ({
  type: RETURNORDER_ITEMS_SUCCESS,
  payload: response,
});
export const returnOrderItemsError = (message) => ({
  type: RETURNORDER_ITEMS_ERROR,
  payload: message,
});

export const manageRefundRequest = (request) => ({
  type: MANAGE_REFUND_REQUEST,
  payload: request,
});
export const manageRefundSuccess = (response) => ({
  type: MANAGE_REFUND_SUCCESS,
  payload: response,
});
export const manageRefundError = (message) => ({
  type: MANAGE_REFUND_ERROR,
  payload: message,
});

export const orderRequest = (request) => ({
  type: ORDER_REQUEST,
  payload: request,
});
export const orderSuccess = (response) => ({
  type: ORDER_SUCCESS,
  payload: response,
});
export const orderError = (message) => ({
  type: ORDER_ERROR,
  payload: message,
});
export const orderItemsRequest = (request) => ({
  type: ORDER_ITEMS_REQUEST,
  payload: request,
});
export const orderItemsSuccess = (response) => ({
  type: ORDER_ITEMS_SUCCESS,
  payload: response,
});
export const orderItemsError = (message) => ({
  type: ORDER_ITEMS_ERROR,
  payload: message,
});

export const orderHistoryRequest = (request) => ({
  type: ORDER_HISTORY_REQUEST,
  payload: request,
});
export const orderHistorySuccess = (response) => ({
  type: ORDER_HISTORY_SUCCESS,
  payload: response,
});
export const orderHistoryError = (message) => ({
  type: ORDER_HISTORY_ERROR,
  payload: message,
});

export const orderImagesRequest = (request) => ({
  type: ORDER_IMAGES_REQUEST,
  payload: request,
});
export const orderImagesSuccess = (response) => ({
  type: ORDER_IMAGES_SUCCESS,
  payload: response,
});
export const orderImagesError = (message) => ({
  type: ORDER_IMAGES_ERROR,
  payload: message,
});

export const manageOrderItemsRequest = (request) => ({
  type: MANAGE_ORDERITEM_REQUEST,
  payload: request,
});
export const manageOrderItemsSuccess = (response) => ({
  type: MANAGE_ORDERITEM_SUCCESS,
  payload: response,
});
export const manageOrderItemsError = (message) => ({
  type: MANAGE_ORDERITEM_ERROR,
  payload: message,
});

export const cancelItemRequest = (request) => ({
  type: CANCEL_ITEM_REQUEST,
  payload: request,
});
export const cancelItemSuccess = (response) => ({
  type: CANCEL_ITEM_SUCCESS,
  payload: response,
});
export const cancelItemError = (message) => ({
  type: CANCEL_ITEM_ERROR,
  payload: message,
});

export const cancelOrderRequest = (request) => ({
  type: CANCEL_ORDER_REQUEST,
  payload: request,
});
export const cancelOrderSuccess = (response) => ({
  type: CANCEL_ORDER_SUCCESS,
  payload: response,
});
export const cancelOrderError = (message) => ({
  type: CANCEL_ORDER_ERROR,
  payload: message,
});

export const deleteOrderItemRequest = (request) => ({
  type: DELETE_ORDERITEM_REQUEST,
  payload: request,
});
export const deleteOrderItemSuccess = (response) => ({
  type: DELETE_ORDERITEM_SUCCESS,
  payload: response,
});
export const deleteOrderItemError = (message) => ({
  type: DELETE_ORDERITEM_ERROR,
  payload: message,
});

export const orderItemHistoryRequest = (request) => ({
  type: ORDERITEM_HISTROY_REQUEST,
  payload: request,
});
export const orderItemHistorySuccess = (response) => ({
  type: ORDERITEM_HISTROY_SUCCESS,
  payload: response,
});
export const orderItemHistoryError = (message) => ({
  type: ORDERITEM_HISTROY_ERROR,
  payload: message,
});
