import { combineReducers } from "redux";
import user_reducer from "./user/reducer";
import utils_reducer from "./utils/reducer";
import product_reducer from "./product/reducer";
import order_reducer from "./order/reducer";
import item_reducer from "./item/reducer";
import labtest_reducer from "./labtest/reducer";

const reducers = combineReducers({
  user_reducer,
  utils_reducer,
  product_reducer,
  order_reducer,
  item_reducer,
  labtest_reducer,
});

export default reducers;
