import {
  CLEAR_LABTEST_DEFAULTS,
  DELETE_LABTESTIMAGE_ERROR,
  DELETE_LABTESTIMAGE_REQUEST,
  DELETE_LABTESTIMAGE_SUCCESS,
  DELETE_TESTINCLUDE_ERROR,
  DELETE_TESTINCLUDE_REQUEST,
  DELETE_TESTINCLUDE_SUCCESS,
  LABTESTIMAGE_LIST_ERROR,
  LABTESTIMAGE_LIST_REQUEST,
  LABTESTIMAGE_LIST_SUCCESS,
  LABTESTINFO_ERROR,
  LABTESTINFO_REQUEST,
  LABTESTINFO_SUCCESS,
  LABTEST_ERROR,
  LABTEST_LIST_ERROR,
  LABTEST_LIST_REQUEST,
  LABTEST_LIST_SUCCESS,
  LABTEST_REQUEST,
  LABTEST_SUCCESS,
  MANAGE_LABTESTIMAGE_ERROR,
  MANAGE_LABTESTIMAGE_REQUEST,
  MANAGE_LABTESTIMAGE_SUCCESS,
  MANAGE_LABTESTINFO_ERROR,
  MANAGE_LABTESTINFO_REQUEST,
  MANAGE_LABTESTINFO_SUCCESS,
  MANAGE_LABTEST_ERROR,
  MANAGE_LABTEST_REQUEST,
  MANAGE_LABTEST_SUCCESS,
  UPDATE_LABTESTSTATUS_ERROR,
  UPDATE_LABTESTSTATUS_REQUEST,
  UPDATE_LABTESTSTATUS_SUCCESS,
} from "../actions";

const INIT = {
  labtest_loading: false,
  labtest_action: null,
  labtest_success: null,
  labtest_error: null,
  labtest_list: [],
  labtest_info: null,
  labtest_image_list: [],
  labtest: null,
};

export default (state = INIT, action) => {
  switch (action.type) {
    case CLEAR_LABTEST_DEFAULTS:
      return {
        ...state,
        labtest_loading: false,
        labtest_success: null,
        labtest_error: null,
        labtest_action: null,
      };
    case MANAGE_LABTEST_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
      };
    case MANAGE_LABTEST_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_success: action.payload,
      };
    case MANAGE_LABTEST_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    case LABTEST_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
        labtest: null,
      };
    case LABTEST_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest: action.payload,
      };
    case LABTEST_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    case LABTEST_LIST_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
        labtest_list: [],
      };
    case LABTEST_LIST_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_list: action.payload,
      };
    case LABTEST_LIST_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };
    case MANAGE_LABTESTINFO_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
      };
    case MANAGE_LABTESTINFO_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_success: action.payload,
      };
    case MANAGE_LABTESTINFO_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };
    case LABTESTINFO_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
        labtest_info: null,
      };
    case LABTESTINFO_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_info: action.payload,
      };
    case LABTESTINFO_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    case MANAGE_LABTESTIMAGE_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
      };
    case MANAGE_LABTESTIMAGE_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_success: action.payload,
      };
    case MANAGE_LABTESTIMAGE_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    case LABTESTIMAGE_LIST_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
        labtest_image_list: [],
      };
    case LABTESTIMAGE_LIST_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_image_list: action.payload,
      };
    case LABTESTIMAGE_LIST_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    case DELETE_LABTESTIMAGE_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
      };
    case DELETE_LABTESTIMAGE_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_success: action.payload,
      };
    case DELETE_LABTESTIMAGE_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    case UPDATE_LABTESTSTATUS_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
      };
    case UPDATE_LABTESTSTATUS_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_success: action.payload,
      };
    case UPDATE_LABTESTSTATUS_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    case DELETE_TESTINCLUDE_REQUEST:
      return {
        ...state,
        labtest_loading: true,
        labtest_success: null,
        labtest_error: null,
        labtest_action: action.type,
      };
    case DELETE_TESTINCLUDE_SUCCESS:
      return {
        ...state,
        labtest_loading: false,
        labtest_success: action.payload,
      };
    case DELETE_TESTINCLUDE_ERROR:
      return {
        ...state,
        labtest_loading: false,
        labtest_error: action.payload,
      };

    default:
      return { ...state };
  }
};
