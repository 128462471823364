import {
  DELETE_PRODUCTIMAGE_ERROR,
  DELETE_PRODUCTIMAGE_REQUEST,
  DELETE_PRODUCTIMAGE_SUCCESS,
  MANAGE_PRODUCTIMAGE_ERROR,
  MANAGE_PRODUCTIMAGE_REQUEST,
  MANAGE_PRODUCTIMAGE_SUCCESS,
  MANAGE_PRODUCTINFO_ERROR,
  MANAGE_PRODUCTINFO_REQUEST,
  MANAGE_PRODUCTINFO_SUCCESS,
  MANAGE_PRODUCT_ERROR,
  MANAGE_PRODUCT_REQUEST,
  MANAGE_PRODUCT_SUCCESS,
  PRODUCTIMAGE_LIST_ERROR,
  PRODUCTIMAGE_LIST_REQUEST,
  PRODUCTIMAGE_LIST_SUCCESS,
  PRODUCTINFO_ERROR,
  PRODUCTINFO_REQUEST,
  PRODUCTINFO_SUCCESS,
  PRODUCT_LIST_ERROR,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  UPDATE_PRODUCTSTATUS_ERROR,
  UPDATE_PRODUCTSTATUS_REQUEST,
  UPDATE_PRODUCTSTATUS_SUCCESS,
} from "../actions";

const INIT = {
  product_loading: false,
  product_action: null,
  product_success: null,
  product_error: null,
  product_list: [],
  product_info: null,
  product_image_list: [],
};

export default (state = INIT, action) => {
  switch (action.type) {
    case MANAGE_PRODUCT_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
      };
    case MANAGE_PRODUCT_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_success: action.payload,
      };
    case MANAGE_PRODUCT_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
        product_list: [],
      };
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_list: action.payload,
      };
    case PRODUCT_LIST_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };
    case MANAGE_PRODUCTINFO_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
      };
    case MANAGE_PRODUCTINFO_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_success: action.payload,
      };
    case MANAGE_PRODUCTINFO_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };
    case PRODUCTINFO_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
        product_info: null,
      };
    case PRODUCTINFO_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_info: action.payload,
      };
    case PRODUCTINFO_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };

    case MANAGE_PRODUCTIMAGE_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
      };
    case MANAGE_PRODUCTIMAGE_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_success: action.payload,
      };
    case MANAGE_PRODUCTIMAGE_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };

    case PRODUCTIMAGE_LIST_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
        product_image_list: [],
      };
    case PRODUCTIMAGE_LIST_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_image_list: action.payload,
      };
    case PRODUCTIMAGE_LIST_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };

    case DELETE_PRODUCTIMAGE_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
      };
    case DELETE_PRODUCTIMAGE_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_success: action.payload,
      };
    case DELETE_PRODUCTIMAGE_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };

    case UPDATE_PRODUCTSTATUS_REQUEST:
      return {
        ...state,
        product_loading: true,
        product_success: null,
        product_error: null,
        product_action: action.type,
      };
    case UPDATE_PRODUCTSTATUS_SUCCESS:
      return {
        ...state,
        product_loading: false,
        product_success: action.payload,
      };
    case UPDATE_PRODUCTSTATUS_ERROR:
      return {
        ...state,
        product_loading: false,
        product_error: action.payload,
      };
    default:
      return { ...state };
  }
};
