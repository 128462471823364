import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Button, message } from "antd";
import { loginRequest, setRequest } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { localeData } from "moment";

export const Signin = (props) => {
  const { request, setRequest, user_loading, user_error, user_action } = props;
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (user_action == "LOGIN_REQUEST") {
      if (user_error) {
        message.error(JSON.stringify(user_error));
      }
    }
  }, [user_loading]);
  const onSubmit = (data) => {
    props.loginAsync(data, history);
  };

  return (
    <Modal
      visible={request.login_modal}
      footer={null}
      centered
      width={"auto"}
      style={{
        marginRight: "10%",
        marginLeft: "10%",
        marginTop: "2%",
        marginBottom: "0%",
      }}
      // onOk={handleOk}
      onCancel={() => setRequest({ ...request, login_modal: false })}
    >
      <div id="login" className="container-fluid">
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <img src="./loginImg.jpg" className="img-fluid" />
            <div className="loginContent">
              <div className="row">
                <div className="col-md-9">
                  <h5>Welcome to</h5>
                  <h2>Online Pharma</h2>
                </div>
                <div className="col-md-3 text-right"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-5">
            <div className="loginHeaderImg pt-3">
              <img src="profileImg.png" alt="profileImg" />
              <h2>BE ONE OF US!</h2>
            </div>
            <div className="login-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <img src="formIcon01(4).png" />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="EMAIL/MOBILE"
                    autoComplete="off"
                    name="email"
                    {...register("email")}
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <img src="formIcon01(2).png" />
                    </div>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="PASSWORD"
                    autoComplete="off"
                    name="password"
                    {...register("password")}
                  />
                </div>
                <Button
                  loading={user_loading && user_action == "LOGIN_REQUEST"}
                  className="primaryBtnFull"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  SIGN IN
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ utils_reducer, user_reducer }) => {
  const { request } = utils_reducer;
  const { user_loading, user_error, user_action } = user_reducer;
  return { request, user_loading, user_error, user_action };
};

const mapDispatchToProps = {
  setRequest: setRequest,
  loginAsync: loginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
