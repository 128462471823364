import {
  CLEAR_LABTEST_DEFAULTS,
  DELETE_LABTESTIMAGE_ERROR,
  DELETE_LABTESTIMAGE_REQUEST,
  DELETE_LABTESTIMAGE_SUCCESS,
  DELETE_TESTINCLUDE_ERROR,
  DELETE_TESTINCLUDE_REQUEST,
  DELETE_TESTINCLUDE_SUCCESS,
  LABTESTIMAGE_LIST_ERROR,
  LABTESTIMAGE_LIST_REQUEST,
  LABTESTIMAGE_LIST_SUCCESS,
  LABTESTINFO_ERROR,
  LABTESTINFO_REQUEST,
  LABTESTINFO_SUCCESS,
  LABTEST_ERROR,
  LABTEST_LIST_ERROR,
  LABTEST_LIST_REQUEST,
  LABTEST_LIST_SUCCESS,
  LABTEST_REQUEST,
  LABTEST_SUCCESS,
  MANAGE_LABTESTIMAGE_ERROR,
  MANAGE_LABTESTIMAGE_REQUEST,
  MANAGE_LABTESTIMAGE_SUCCESS,
  MANAGE_LABTESTINFO_ERROR,
  MANAGE_LABTESTINFO_REQUEST,
  MANAGE_LABTESTINFO_SUCCESS,
  MANAGE_LABTEST_ERROR,
  MANAGE_LABTEST_REQUEST,
  MANAGE_LABTEST_SUCCESS,
  UPDATE_LABTESTSTATUS_ERROR,
  UPDATE_LABTESTSTATUS_REQUEST,
  UPDATE_LABTESTSTATUS_SUCCESS,
} from "../actions";

export const clearLabTestDefaults = (message) => ({
  type: CLEAR_LABTEST_DEFAULTS,
  payload: message,
});

export const manageLabTestRequest = (request) => ({
  payload: request,
  type: MANAGE_LABTEST_REQUEST,
});
export const manageLabTestSuccess = (response) => ({
  payload: response,
  type: MANAGE_LABTEST_SUCCESS,
});

export const manageLabTestError = (message) => ({
  payload: message,
  type: MANAGE_LABTEST_ERROR,
});

export const LabTesttRequest = (request) => ({
  payload: request,
  type: LABTEST_REQUEST,
});
export const LabTestSuccess = (response) => ({
  payload: response,
  type: LABTEST_SUCCESS,
});

export const LabTestError = (message) => ({
  payload: message,
  type: LABTEST_ERROR,
});

export const LabTestsListRequest = (request) => ({
  payload: request,
  type: LABTEST_LIST_REQUEST,
});
export const LabTestsListSuccess = (response) => ({
  payload: response,
  type: LABTEST_LIST_SUCCESS,
});

export const LabTestsListError = (message) => ({
  payload: message,
  type: LABTEST_LIST_ERROR,
});

export const manageLabTestInfoRequest = (request) => ({
  payload: request,
  type: MANAGE_LABTESTINFO_REQUEST,
});
export const manageLabTestInfoSuccess = (response) => ({
  payload: response,
  type: MANAGE_LABTESTINFO_SUCCESS,
});

export const manageLabTestInfoError = (message) => ({
  payload: message,
  type: MANAGE_LABTESTINFO_ERROR,
});

export const LabTestInfoRequest = (request) => ({
  payload: request,
  type: LABTESTINFO_REQUEST,
});
export const LabTestInfoSuccess = (response) => ({
  payload: response,
  type: LABTESTINFO_SUCCESS,
});

export const LabTestInfoError = (message) => ({
  payload: message,
  type: LABTESTINFO_ERROR,
});

export const manageLabTestImageRequest = (request) => ({
  payload: request,
  type: MANAGE_LABTESTIMAGE_REQUEST,
});
export const manageLabTestImageSuccess = (response) => ({
  payload: response,
  type: MANAGE_LABTESTIMAGE_SUCCESS,
});

export const manageLabTestImageError = (message) => ({
  payload: message,
  type: MANAGE_LABTESTIMAGE_ERROR,
});

export const LabTestImageListRequest = (request) => ({
  payload: request,
  type: LABTESTIMAGE_LIST_REQUEST,
});
export const LabTestImageListSuccess = (response) => ({
  payload: response,
  type: LABTESTIMAGE_LIST_SUCCESS,
});

export const LabTestImageListError = (message) => ({
  payload: message,
  type: LABTESTIMAGE_LIST_ERROR,
});

export const deleteLabTestImageRequest = (request) => ({
  payload: request,
  type: DELETE_LABTESTIMAGE_REQUEST,
});
export const deleteLabTestImageSuccess = (response) => ({
  payload: response,
  type: DELETE_LABTESTIMAGE_SUCCESS,
});

export const deleteLabTestImageError = (message) => ({
  payload: message,
  type: DELETE_LABTESTIMAGE_ERROR,
});

export const updateLabTestStatusRequest = (request) => ({
  payload: request,
  type: UPDATE_LABTESTSTATUS_REQUEST,
});
export const updateLabTestStatusSuccess = (response) => ({
  payload: response,
  type: UPDATE_LABTESTSTATUS_SUCCESS,
});

export const updateLabTestStatusError = (message) => ({
  payload: message,
  type: UPDATE_LABTESTSTATUS_ERROR,
});

export const deleteTestIncludeRequest = (request) => ({
  payload: request,
  type: DELETE_TESTINCLUDE_REQUEST,
});
export const deleteTestIncludeSuccess = (response) => ({
  payload: response,
  type: DELETE_TESTINCLUDE_SUCCESS,
});

export const deleteTestIncludeError = (message) => ({
  payload: message,
  type: DELETE_TESTINCLUDE_ERROR,
});
