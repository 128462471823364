import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  GET,
  POST,
  LIST,
  PATCH,
  DELETE,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import { objectToQueryString } from "../../helpers/utils";
import {
  DELETE_LABTESTIMAGE_REQUEST,
  DELETE_TESTINCLUDE_REQUEST,
  LABTESTIMAGE_LIST_REQUEST,
  LABTESTINFO_REQUEST,
  LABTEST_LIST_REQUEST,
  LABTEST_REQUEST,
  MANAGE_LABTESTIMAGE_REQUEST,
  MANAGE_LABTESTINFO_REQUEST,
  MANAGE_LABTEST_REQUEST,
  UPDATE_LABTESTSTATUS_REQUEST,
} from "../actions";
import {
  deleteLabTestImageError,
  deleteLabTestImageSuccess,
  deleteTestIncludeError,
  deleteTestIncludeSuccess,
  LabTestError,
  LabTestImageListError,
  LabTestImageListSuccess,
  LabTestInfoError,
  LabTestInfoSuccess,
  LabTestsListError,
  LabTestsListSuccess,
  LabTestSuccess,
  manageLabTestError,
  manageLabTestImageError,
  manageLabTestImageSuccess,
  manageLabTestInfoError,
  manageLabTestInfoSuccess,
  manageLabTestSuccess,
  updateLabTestStatusError,
  updateLabTestStatusSuccess,
} from "./action";
export function* watchManageLabtest() {
  yield takeLatest(MANAGE_LABTEST_REQUEST, manageLabtest);
}
function* manageLabtest({ payload }) {
  let url = "/Labtest";
  try {
    const response = yield call(
      payload && payload.item_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageLabTestError(response.message));
    } else {
      yield put(manageLabTestSuccess(response));
    }
  } catch (ex) {
    yield put(manageLabTestError(ex));
  }
}

export function* watchLabtest() {
  yield takeLatest(LABTEST_REQUEST, Labtest);
}
function* Labtest({ payload }) {
  let url = `/Labtest/${payload.item_id}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(LabTestError(response.message));
    } else {
      yield put(LabTestSuccess(response));
    }
  } catch (ex) {
    yield put(LabTestError(ex));
  }
}

export function* watchLabtestList() {
  yield takeLatest(LABTEST_LIST_REQUEST, LabtestList);
}
function* LabtestList({ payload }) {
  let url = `/Labtest?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(LabTestsListError(response.message));
    } else {
      yield put(LabTestsListSuccess(response));
    }
  } catch (ex) {
    yield put(LabTestsListError(ex));
  }
}

export function* watchManageLabtestInfo() {
  yield takeLatest(MANAGE_LABTESTINFO_REQUEST, manageLabtestInfo);
}
function* manageLabtestInfo({ payload }) {
  let url = `/Labtest/${payload.item_id}/Labtestinfo`;
  try {
    const response = yield call(
      payload && payload.info_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageLabTestInfoError(response.message));
    } else {
      yield put(manageLabTestInfoSuccess(response));
    }
  } catch (ex) {
    yield put(manageLabTestInfoError(ex));
  }
}

export function* watchLabtestInfo() {
  yield takeLatest(LABTESTINFO_REQUEST, LabtestInfo);
}
function* LabtestInfo({ payload }) {
  let url = `/Labtest/${payload.item_id}/Labtestinfo`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(LabTestInfoError(response.message));
    } else {
      yield put(LabTestInfoSuccess(response));
    }
  } catch (ex) {
    yield put(LabTestInfoError(ex));
  }
}

export function* watchManageLabtestImage() {
  yield takeLatest(MANAGE_LABTESTIMAGE_REQUEST, manageLabtestImage);
}
function* manageLabtestImage({ payload }) {
  let url = `/Labtest/${payload.item_id}/Labtestimage`;
  try {
    const response = yield call(
      payload && payload.image_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageLabTestImageError(response.message));
    } else {
      yield put(manageLabTestImageSuccess(response));
    }
  } catch (ex) {
    yield put(manageLabTestImageError(ex));
  }
}
export function* watchLabtestImageList() {
  yield takeLatest(LABTESTIMAGE_LIST_REQUEST, LabtestImageList);
}
function* LabtestImageList({ payload }) {
  let url = `/Labtest/${payload.item_id}/Labtestimage?${objectToQueryString(
    payload
  )}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(LabTestImageListError(response.message));
    } else {
      yield put(LabTestImageListSuccess(response));
    }
  } catch (ex) {
    yield put(LabTestImageListError(ex));
  }
}

export function* watchDeleteLabtestImage() {
  yield takeLatest(DELETE_LABTESTIMAGE_REQUEST, deleteLabtestImage);
}
function* deleteLabtestImage({ payload }) {
  let url = `/Labtest/${payload.item_id}/Labtestimage?${objectToQueryString(
    payload
  )}`;
  try {
    const response = yield call(DELETE, url, payload);
    if (response && response.is_error) {
      yield put(deleteLabTestImageError(response.message));
    } else {
      yield put(deleteLabTestImageSuccess(response));
    }
  } catch (ex) {
    yield put(deleteLabTestImageError(ex));
  }
}

export function* watchUpdateLabtesttatus() {
  yield takeLatest(UPDATE_LABTESTSTATUS_REQUEST, updateLabtesttatus);
}
function* updateLabtesttatus({ payload }) {
  let url = `/Labtest`;
  try {
    const response = yield call(PATCH, url, payload);
    alert(JSON.stringify(response));
    if (response && response.is_error) {
      yield put(updateLabTestStatusError(response.message));
    } else {
      yield put(updateLabTestStatusSuccess(response));
    }
  } catch (ex) {
    yield put(updateLabTestStatusError(ex));
  }
}

export function* watchDeleteInclude() {
  yield takeLatest(DELETE_TESTINCLUDE_REQUEST, deleteTestInclude);
}
function* deleteTestInclude({ payload }) {
  let url = `/Labtest/${payload.item_id}/testinclude`;
  try {
    const response = yield call(DELETE, url, payload);
    if (response && response.is_error) {
      yield put(deleteTestIncludeError(response.message));
    } else {
      yield put(deleteTestIncludeSuccess(response));
    }
  } catch (ex) {
    yield put(deleteTestIncludeError(ex));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchManageLabtest),
    fork(watchLabtestList),
    fork(watchManageLabtestInfo),
    fork(watchLabtestInfo),
    fork(watchManageLabtestImage),
    fork(watchLabtestImageList),
    fork(watchDeleteLabtestImage),
    fork(watchUpdateLabtesttatus),
    fork(watchLabtest),
    fork(watchDeleteInclude),
  ]);
}
