import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";
import {
  ADD_USER_ORDER_BY_PRESCRPTION_REQUEST,
  ADD_USER_ORDER_REQUEST,
  BATCHNO_LIST_REQUEST,
  CANCEL_ITEM_REQUEST,
  CANCEL_ORDER_REQUEST,
  CHECK_PAYMENT_REQUEST,
  DELETE_ITEM_REQUEST,
  DELETE_ORDERITEM_REQUEST,
  DELIVER_INFO_REQUEST,
  GET_USER_ORDER_DETAILS_REQUEST,
  GET_USER_ORDER_REQUEST,
  ORDERITEM_HISTROY_REQUEST,
  MANAGE_BILL_REQUEST,
  MANAGE_ONLINE_DELIVER_REQUEST,
  MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  MANAGE_ORDERITEM_REQUEST,
  MANAGE_PHARMACY_REQUEST,
  MANAGE_REFUND_REQUEST,
  MANAGE_TEMP_ORDER_REQUEST,
  ORDERSTATUS_REQUEST,
  ORDER_HISTORY_REQUEST,
  ORDER_IMAGES_REQUEST,
  ORDER_INFO_REQUEST,
  ORDER_ITEMS_REQUEST,
  ORDER_LIST_REQUEST,
  ORDER_REQUEST,
  PAYMENT_REQUEST,
  PHARMACYBILL_INFO_REQUEST,
  PHARMACY_GATEWAY_REQUEST,
  PRODUCT_GATEWAY_REQUEST,
  RETURNORDERS_REQUEST,
  RETURNORDER_INFO_REQUEST,
  RETURNORDER_ITEMS_REQUEST,
  UPDATE_ORDERITEMSTATUS_REQUEST,
} from "../actions";
import { GET, POST, LIST, PATCH, FORMDATA_POST, DELETE } from "../api";
import {
  addOrderFailure,
  addOrderSuccess,
  batchNoListError,
  batchNoListSuccess,
  cancelItemError,
  cancelItemSuccess,
  cancelOrderError,
  cancelOrderSuccess,
  checkPaymentError,
  checkPaymentSuccess,
  deleteItemError,
  deleteItemSuccess,
  deleteOrderItemError,
  deleteOrderItemSuccess,
  deliverInfoError,
  deliverInfoSuccess,
  deliverStatusError,
  deliverStatusSuccess,
  getOrderFailure,
  getOrderRequest,
  getOrdersFailure,
  getOrdersRequest,
  orderItemHistoryError,
  orderItemHistorySuccess,
  manageBillError,
  manageBillSuccess,
  manageOnlineDeliverError,
  manageOnlineDeliverSuccess,
  manageOnlineOrderStatusError,
  manageOnlineOrderStatusSuccess,
  manageOrderItemsError,
  manageOrderItemsSuccess,
  managePharmacyError,
  managePharmacySuccess,
  manageRefundError,
  manageRefundSuccess,
  manageTempOrderError,
  manageTempOrderSuccess,
  orderError,
  orderHistoryError,
  orderHistorySuccess,
  orderImagesError,
  orderImagesSuccess,
  orderInfoError,
  orderInfoSuccess,
  orderItemsError,
  orderItemsSuccess,
  orderListError,
  orderListSuccess,
  orderStatusError,
  orderStatusSuccess,
  orderSuccess,
  paymentError,
  paymentSuccess,
  pharmacyBillInfoError,
  pharmacyBillInfoSuccess,
  pharmacyGatewayError,
  pharmacyGatewaySuccess,
  productGatewayError,
  productGatewaySuccess,
  returnOrderInfoError,
  returnOrderInfoSuccess,
  returnOrderItemsError,
  returnOrderItemsSuccess,
  returnOrdersError,
  returnOrdersSuccess,
  updateOrderItemStatusError,
  updateOrderItemStatusSuccess,
} from "./action";

export function* watchManagePharmacy() {
  yield takeLatest(MANAGE_PHARMACY_REQUEST, managePharmacy);
}

function* managePharmacy({ payload }) {
  let url = "/order/pharmacy";
  try {
    const response = yield call(FORMDATA_POST, url, payload);
    if (response && response.is_error) {
      yield put(managePharmacyError(response.message));
    } else {
      yield put(managePharmacySuccess(response));
    }
  } catch (ex) {
    yield put(managePharmacyError(ex));
  }
}

export function* watchOrdersList() {
  yield takeLatest(ORDER_LIST_REQUEST, ordersList);
}

function* ordersList({ payload }) {
  let url = `/order?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(orderListError(response.message));
    } else {
      yield put(orderListSuccess(response));
    }
  } catch (ex) {
    yield put(orderListError(ex));
  }
}

export function* watchOrderInfo() {
  yield takeLatest(ORDER_INFO_REQUEST, orderInfo);
}

function* orderInfo({ payload }) {
  let url = `/order?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(orderInfoError(response.message));
    } else {
      yield put(orderInfoSuccess(response));
    }
  } catch (ex) {
    yield put(orderInfoError(ex));
  }
}

export function* watchPharmacyGateway() {
  yield takeLatest(PHARMACY_GATEWAY_REQUEST, pharmacyGateway);
}

function* pharmacyGateway({ payload }) {
  let url = "/payment/pharmacygateway";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(pharmacyGatewayError(response.message));
    } else {
      yield put(pharmacyGatewaySuccess(response));
    }
  } catch (ex) {
    yield put(pharmacyGatewayError(ex));
  }
}

export function* watchProductGateway() {
  yield takeLatest(PRODUCT_GATEWAY_REQUEST, productGateway);
}

function* productGateway({ payload }) {
  let url = "/payment/productgateway";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(productGatewayError(response.message));
    } else {
      yield put(productGatewaySuccess(response));
    }
  } catch (ex) {
    yield put(productGatewayError(ex));
  }
}

export function* watchCheckPayment() {
  yield takeLatest(CHECK_PAYMENT_REQUEST, checkPayment);
}

function* checkPayment({ payload }) {
  let url = "/payment/checkpayment";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(checkPaymentError(response.message));
    } else {
      yield put(checkPaymentSuccess(response));
    }
  } catch (ex) {
    yield put(checkPaymentError(ex));
  }
}

export function* watchManageTempOrder() {
  yield takeLatest(MANAGE_TEMP_ORDER_REQUEST, manageTempOrder);
}

function* manageTempOrder({ payload }) {
  let url = "/order/temporder";
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageTempOrderError(response.message));
    } else {
      yield put(manageTempOrderSuccess(response));
    }
  } catch (ex) {
    yield put(manageTempOrderError(ex));
  }
}

export function* watchOrderStatus() {
  yield takeLatest(ORDERSTATUS_REQUEST, orderStatus);
}

function* orderStatus({ payload }) {
  let url = `/order/orderstatus?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(orderStatusError(response.message));
    } else {
      yield put(orderStatusSuccess(response));
    }
  } catch (ex) {
    yield put(orderStatusError(ex));
  }
}

export function* watchDeliverInfo() {
  yield takeLatest(DELIVER_INFO_REQUEST, deliverRequest);
}

function* deliverRequest({ payload }) {
  let url = `/order/deliverinfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(deliverInfoError(response.message));
    } else {
      yield put(deliverInfoSuccess(response));
    }
  } catch (ex) {
    yield put(deliverInfoError(ex));
  }
}

export function* watchPharmacyBillInfo() {
  yield takeLatest(PHARMACYBILL_INFO_REQUEST, pharmacyBillInfo);
}

function* pharmacyBillInfo({ payload }) {
  let url = `/order/billinfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(pharmacyBillInfoError(response.message));
    } else {
      yield put(pharmacyBillInfoSuccess(response));
    }
  } catch (ex) {
    yield put(pharmacyBillInfoError(ex));
  }
}

export function* watchManageOnlineStatus() {
  yield takeLatest(MANAGE_ONLINE_ORDERSTATUS_REQUEST, manageOnlineOrderStatus);
}

function* manageOnlineOrderStatus({ payload }) {
  const url = `/order/${payload.order_id}/OrderStatus`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(manageOnlineOrderStatusError(response.message));
    } else {
      yield put(manageOnlineOrderStatusSuccess(response));
    }
  } catch (error) {
    yield put(manageOnlineOrderStatusError(error));
  }
}

export function* watchmanageOnlineDeliver() {
  yield takeLatest(MANAGE_ONLINE_DELIVER_REQUEST, manageOnlineDeliver);
}

function* manageOnlineDeliver({ payload }) {
  const url = `/order/${payload.order_id}/DeliverStatus`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageOnlineDeliverError(response.message));
    } else {
      yield put(manageOnlineDeliverSuccess(response));
    }
  } catch (error) {
    yield put(manageOnlineDeliverError(error));
  }
}

export function* watchupdateOrderItemStatus() {
  yield takeLatest(UPDATE_ORDERITEMSTATUS_REQUEST, updateOrderItemStatus);
}

function* updateOrderItemStatus({ payload }) {
  const url = `/order/${payload.order_id}/itemstatus`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateOrderItemStatusError(response.message));
    } else {
      yield put(updateOrderItemStatusSuccess(response));
    }
  } catch (error) {
    yield put(updateOrderItemStatusError(error));
  }
}

export function* watchManageBill() {
  yield takeLatest(MANAGE_BILL_REQUEST, manageBill);
}

function* manageBill({ payload }) {
  const url = `/pharmacy`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageBillError(response.message));
    } else {
      yield put(manageBillSuccess(response));
    }
  } catch (error) {
    yield put(manageBillError(error));
  }
}

export function* watchBatchNoList() {
  yield takeLatest(BATCHNO_LIST_REQUEST, batchNoList);
}

function* batchNoList({ payload }) {
  const url = `/pharmacy/BatchList?item_id=${payload}`;
  try {
    const response = yield call(GET, url);
    if (response && response.is_error) {
      yield put(batchNoListError(response.message));
    } else {
      yield put(batchNoListSuccess(response));
    }
  } catch (error) {
    yield put(batchNoListError(error));
  }
}

export function* watchReturnOrders() {
  yield takeLatest(RETURNORDERS_REQUEST, returnOrders);
}

function* returnOrders({ payload }) {
  const url = `/order/returnorders`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(returnOrdersError(response.message));
    } else {
      yield put(returnOrdersSuccess(response));
    }
  } catch (error) {
    yield put(returnOrdersError(error));
  }
}

export function* watchReturnOrderInfo() {
  yield takeLatest(RETURNORDER_INFO_REQUEST, returnOrderInfo);
}

function* returnOrderInfo({ payload }) {
  const url = `/order/${payload.order_id}/returninfo`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(returnOrderInfoError(response.message));
    } else {
      yield put(returnOrderInfoSuccess(response));
    }
  } catch (error) {
    yield put(returnOrderInfoError(error));
  }
}

export function* watchReturnOrderItems() {
  yield takeLatest(RETURNORDER_ITEMS_REQUEST, returnOrderItems);
}

function* returnOrderItems({ payload }) {
  const url = `/order/${payload.order_id}/returnitems`;
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(returnOrderItemsError(response.message));
    } else {
      yield put(returnOrderItemsSuccess(response));
    }
  } catch (error) {
    yield put(returnOrderItemsError(error));
  }
}

export function* watchManageRefund() {
  yield takeLatest(MANAGE_REFUND_REQUEST, manageRefund);
}

function* manageRefund({ payload }) {
  const url = `/refund/initiate`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(manageRefundError(response.message));
    } else {
      yield put(manageRefundSuccess(response));
    }
  } catch (error) {
    yield put(manageRefundError(error));
  }
}
export function* watchOrder() {
  yield takeLatest(ORDER_REQUEST, order);
}

function* order({ payload }) {
  let url = `/order/${payload.order_id}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(orderError(response.message));
    } else {
      yield put(orderSuccess(response));
    }
  } catch (ex) {
    yield put(orderError(ex));
  }
}

export function* watchOrderItems() {
  yield takeLatest(ORDER_ITEMS_REQUEST, orderItems);
}

function* orderItems({ payload }) {
  let url = `/order/${payload.order_id}/orderitems`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(orderItemsError(response.message));
    } else {
      yield put(orderItemsSuccess(response));
    }
  } catch (ex) {
    yield put(orderItemsError(ex));
  }
}
export function* watchOrderHistory() {
  yield takeLatest(ORDER_HISTORY_REQUEST, orderHistory);
}

function* orderHistory({ payload }) {
  let url = `/order/${payload.order_id}/orderhistory`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(orderHistoryError(response.message));
    } else {
      yield put(orderHistorySuccess(response));
    }
  } catch (ex) {
    yield put(orderHistoryError(ex));
  }
}

export function* watchOrderImages() {
  yield takeLatest(ORDER_IMAGES_REQUEST, orderImages);
}

function* orderImages({ payload }) {
  let url = `/order/${payload.order_id}/orderimages`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(orderImagesError(response.message));
    } else {
      yield put(orderImagesSuccess(response));
    }
  } catch (ex) {
    yield put(orderImagesError(ex));
  }
}

export function* watchManageOrderItem() {
  yield takeLatest(MANAGE_ORDERITEM_REQUEST, manageOrderItem);
}

function* manageOrderItem({ payload }) {
  let url = `/order/${payload.order_id}/orderitems`;
  try {
    const response = yield call(
      payload && payload.order_item_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageOrderItemsError(response.message));
    } else {
      yield put(manageOrderItemsSuccess(response));
    }
  } catch (ex) {
    yield put(manageOrderItemsError(ex));
  }
}

export function* watchCancelItem() {
  yield takeLatest(CANCEL_ITEM_REQUEST, cancelItem);
}

function* cancelItem({ payload }) {
  let url = `/return/${payload.order_id}/item`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(cancelItemError(response.message));
    } else {
      yield put(cancelItemSuccess(response));
    }
  } catch (ex) {
    yield put(cancelItemError(ex));
  }
}

export function* watchCancelOrder() {
  yield takeLatest(CANCEL_ORDER_REQUEST, cancelOrder);
}

function* cancelOrder({ payload }) {
  let url = `/return/${payload.order_id}/order`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(cancelOrderError(response.message));
    } else {
      yield put(cancelOrderSuccess(response));
    }
  } catch (ex) {
    yield put(cancelOrderError(ex));
  }
}

export function* watchDeleteOrderItem() {
  yield takeLatest(DELETE_ORDERITEM_REQUEST, deleteOrderItem);
}

function* deleteOrderItem({ payload }) {
  let url = `/order/${payload.order_id}/orderitems`;
  try {
    const response = yield call(DELETE, url, payload);
    if (response && response.is_error) {
      yield put(deleteOrderItemError(response.message));
    } else {
      yield put(deleteOrderItemSuccess(response));
    }
  } catch (ex) {
    yield put(deleteOrderItemError(ex));
  }
}

export function* watchorderItemHistory() {
  yield takeLatest(ORDERITEM_HISTROY_REQUEST, orderItemHistory);
}

function* orderItemHistory({ payload }) {
  let url = `/order/${payload.order_id}/orderhistory/${payload.order_item_id}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(orderItemHistoryError(response.message));
    } else {
      yield put(orderItemHistorySuccess(response));
    }
  } catch (ex) {
    yield put(orderItemHistoryError(ex));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchManagePharmacy),
    fork(watchOrdersList),
    fork(watchOrderInfo),
    fork(watchCheckPayment),
    fork(watchPharmacyGateway),
    fork(watchManageTempOrder),
    fork(watchProductGateway),
    fork(watchOrderStatus),
    fork(watchDeliverInfo),
    fork(watchPharmacyBillInfo),
    fork(watchManageOnlineStatus),
    fork(watchmanageOnlineDeliver),
    fork(watchManageBill),
    fork(watchBatchNoList),
    fork(watchReturnOrders),
    fork(watchReturnOrderInfo),
    fork(watchReturnOrderItems),
    fork(watchManageRefund),
    fork(watchOrder),
    fork(watchOrderItems),
    fork(watchOrderHistory),
    fork(watchOrderImages),
    fork(watchManageOrderItem),
    fork(watchCancelItem),
    fork(watchCancelOrder),
    fork(watchDeleteOrderItem),
    fork(watchupdateOrderItemStatus),
    fork(watchorderItemHistory),
  ]);
}
