import {
  BATCHNO_LIST_ERROR,
  BATCHNO_LIST_REQUEST,
  BATCHNO_LIST_SUCCESS,
  CANCEL_ITEM_ERROR,
  CANCEL_ITEM_REQUEST,
  CANCEL_ITEM_SUCCESS,
  CANCEL_ORDER_ERROR,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CHECK_PAYMENT_ERROR,
  CHECK_PAYMENT_REQUEST,
  CHECK_PAYMENT_SUCCESS,
  CLEAR_ORDER_DEFAULTS,
  DELETE_ITEM_ERROR,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ORDERITEM_ERROR,
  DELETE_ORDERITEM_REQUEST,
  DELETE_ORDERITEM_SUCCESS,
  DELIVER_ERROR,
  DELIVER_INFO_ERROR,
  DELIVER_INFO_REQUEST,
  DELIVER_INFO_SUCCESS,
  DELIVER_REQUEST,
  DELIVER_SUCCESS,
  ORDERITEM_HISTROY_ERROR,
  ORDERITEM_HISTROY_REQUEST,
  ORDERITEM_HISTROY_SUCCESS,
  MANAGE_BILL_ERROR,
  MANAGE_BILL_REQUEST,
  MANAGE_BILL_SUCCESS,
  MANAGE_ONLINE_DELIVER_ERROR,
  MANAGE_ONLINE_DELIVER_REQUEST,
  MANAGE_ONLINE_DELIVER_SUCCESS,
  MANAGE_ONLINE_ORDERSTATUS_ERROR,
  MANAGE_ONLINE_ORDERSTATUS_REQUEST,
  MANAGE_ONLINE_ORDERSTATUS_SUCCESS,
  MANAGE_ORDERITEM_ERROR,
  MANAGE_ORDERITEM_REQUEST,
  MANAGE_ORDERITEM_SUCCESS,
  MANAGE_PHARMACY_ERROR,
  MANAGE_PHARMACY_REQUEST,
  MANAGE_PHARMACY_SUCCESS,
  MANAGE_REFUND_ERROR,
  MANAGE_REFUND_REQUEST,
  MANAGE_REFUND_SUCCESS,
  MANAGE_TEMP_ORDER_ERROR,
  MANAGE_TEMP_ORDER_REQUEST,
  MANAGE_TEMP_ORDER_SUCCESS,
  ORDERSTATUS_ERROR,
  ORDERSTATUS_REQUEST,
  ORDERSTATUS_SUCCESS,
  ORDER_ERROR,
  ORDER_HISTORY_ERROR,
  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_SUCCESS,
  ORDER_IMAGES_ERROR,
  ORDER_IMAGES_REQUEST,
  ORDER_IMAGES_SUCCESS,
  ORDER_INFO_ERROR,
  ORDER_INFO_REQUEST,
  ORDER_INFO_SUCCESS,
  ORDER_ITEMS_ERROR,
  ORDER_ITEMS_REQUEST,
  ORDER_ITEMS_SUCCESS,
  ORDER_LIST_ERROR,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  PHARMACYBILL_INFO_ERROR,
  PHARMACYBILL_INFO_REQUEST,
  PHARMACYBILL_INFO_SUCCESS,
  PHARMACY_GATEWAY_ERROR,
  PHARMACY_GATEWAY_REQUEST,
  PHARMACY_GATEWAY_SUCCESS,
  PRODUCT_GATEWAY_ERROR,
  PRODUCT_GATEWAY_REQUEST,
  PRODUCT_GATEWAY_SUCCESS,
  RETURNORDERS_ERROR,
  RETURNORDERS_REQUEST,
  RETURNORDERS_SUCCESS,
  RETURNORDER_INFO_ERROR,
  RETURNORDER_INFO_REQUEST,
  RETURNORDER_INFO_SUCCESS,
  RETURNORDER_ITEMS_ERROR,
  RETURNORDER_ITEMS_REQUEST,
  RETURNORDER_ITEMS_SUCCESS,
  UPDATE_ORDERITEMSTATUS_ERROR,
  UPDATE_ORDERITEMSTATUS_REQUEST,
  UPDATE_ORDERITEMSTATUS_SUCCESS,
} from "../actions";

const initialState = {
  orders: [],
  orderDetails: {},
  error: null,
  loading: false,
  orderFetching: false,
  prescriptionImage: [],
  order_error: null,
  order_success: null,
  order_action: null,
  order_loading: false,
  order_list: [],
  order_info: null,
  payment_fields: null,
  order_status: null,
  deliver_info: null,
  pharmacy_bill_info: null,
  batchno_list: [],
  return_order_list: [],
  return_order_info: null,
  return_order_items: [],
  refund_info: null,
  order: null,
  order_items: [],
  order_images: [],
  order_history: [],
  order_item_history: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORDER_DEFAULTS:
      return {
        ...state,
        order_loading: false,
        order_success: null,
        order_error: null,
        order_action: null,
      };
    case MANAGE_PHARMACY_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case MANAGE_PHARMACY_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };

    case MANAGE_PHARMACY_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case ORDER_LIST_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        order_list: [],
      };
    case ORDER_LIST_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_list: action.payload,
      };
    case ORDER_LIST_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case ORDER_INFO_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        order_info: null,
      };
    case ORDER_INFO_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_info: action.payload,
      };
    case ORDER_INFO_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    case PHARMACY_GATEWAY_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        payment_fields: null,
      };
    case PHARMACY_GATEWAY_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: true,
        payment_fields: action.payload,
      };

    case PHARMACY_GATEWAY_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case PRODUCT_GATEWAY_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        payment_fields: null,
      };
    case PRODUCT_GATEWAY_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: true,
        payment_fields: action.payload,
      };

    case PRODUCT_GATEWAY_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    case CHECK_PAYMENT_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case CHECK_PAYMENT_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };

    case CHECK_PAYMENT_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case MANAGE_TEMP_ORDER_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case MANAGE_TEMP_ORDER_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };

    case MANAGE_TEMP_ORDER_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case ORDERSTATUS_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        order_status: null,
      };
    case ORDERSTATUS_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_status: action.payload,
      };

    case ORDERSTATUS_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case DELIVER_INFO_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        deliver_info: null,
      };
    case DELIVER_INFO_SUCCESS:
      return {
        ...state,
        order_loading: false,
        deliver_info: action.payload,
      };

    case DELIVER_INFO_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case PHARMACYBILL_INFO_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        pharmacy_bill_info: null,
      };
    case PHARMACYBILL_INFO_SUCCESS:
      return {
        ...state,
        order_loading: false,
        pharmacy_bill_info: action.payload,
      };

    case PHARMACYBILL_INFO_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case MANAGE_ONLINE_ORDERSTATUS_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case MANAGE_ONLINE_ORDERSTATUS_SUCCESS: {
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };
    }
    case MANAGE_ONLINE_ORDERSTATUS_ERROR: {
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    }

    case MANAGE_ONLINE_DELIVER_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case MANAGE_ONLINE_DELIVER_SUCCESS: {
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };
    }
    case MANAGE_ONLINE_DELIVER_ERROR: {
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    }

    case UPDATE_ORDERITEMSTATUS_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case UPDATE_ORDERITEMSTATUS_SUCCESS: {
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };
    }
    case UPDATE_ORDERITEMSTATUS_ERROR: {
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    }
    case MANAGE_BILL_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        order_action: action.type,
      };
    case MANAGE_BILL_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };
    case MANAGE_BILL_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case BATCHNO_LIST_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        batchno_list: [],
        order_action: action.type,
      };
    case BATCHNO_LIST_SUCCESS:
      return {
        ...state,
        order_loading: false,
        batchno_list: action.payload,
      };
    case BATCHNO_LIST_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case RETURNORDERS_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        return_order_list: [],
        order_action: action.type,
      };
    case RETURNORDERS_SUCCESS:
      return {
        ...state,
        order_loading: false,
        return_order_list: action.payload,
      };
    case RETURNORDERS_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case RETURNORDER_INFO_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        return_order_info: [],
        order_action: action.type,
      };
    case RETURNORDER_INFO_SUCCESS:
      return {
        ...state,
        order_loading: false,
        return_order_info: action.payload,
      };
    case RETURNORDER_INFO_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    case RETURNORDER_ITEMS_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        return_order_items: [],
        order_action: action.type,
      };
    case RETURNORDER_ITEMS_SUCCESS:
      return {
        ...state,
        order_loading: false,
        return_order_items: action.payload,
      };
    case RETURNORDER_ITEMS_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case MANAGE_REFUND_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        order_action: action.type,
        refund_info: null,
      };
    case MANAGE_REFUND_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: "Success",
        refund_info: action.payload,
      };
    case MANAGE_REFUND_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case ORDER_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        order_action: action.type,
        order: null,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order: action.payload,
      };
    case ORDER_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case ORDER_ITEMS_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        order_action: action.type,
        order_items: [],
      };
    case ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_items: action.payload,
      };
    case ORDER_ITEMS_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    case ORDER_HISTORY_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        order_action: action.type,
        order_history: [],
      };
    case ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_history: action.payload,
      };
    case ORDER_HISTORY_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    case ORDER_IMAGES_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        order_action: action.type,
        order_images: [],
      };
    case ORDER_IMAGES_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_images: action.payload,
      };
    case ORDER_IMAGES_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    case MANAGE_ORDERITEM_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_error: null,
        order_success: null,
        order_action: action.type,
      };
    case MANAGE_ORDERITEM_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };
    case MANAGE_ORDERITEM_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case CANCEL_ITEM_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case CANCEL_ITEM_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };

    case CANCEL_ITEM_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case CANCEL_ORDER_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };

    case CANCEL_ORDER_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case DELETE_ORDERITEM_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
      };
    case DELETE_ORDERITEM_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_success: action.payload,
      };

    case DELETE_ORDERITEM_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    case ORDERITEM_HISTROY_REQUEST:
      return {
        ...state,
        order_loading: true,
        order_success: null,
        order_error: null,
        order_action: action.type,
        order_item_history: [],
      };
    case ORDERITEM_HISTROY_SUCCESS:
      return {
        ...state,
        order_loading: false,
        order_item_history: action.payload,
      };

    case ORDERITEM_HISTROY_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };

    default:
      return { ...state };
  }
};
