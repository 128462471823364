import { all } from "redux-saga/effects";
import utilsSaga from "./utils/saga";
import userSaga from "./user/saga";
import productSaga from "./product/saga";
import orderSaga from "./order/saga";
import itemSaga from "./item/saga";
import labtestSaga from "./labtest/saga";

export default function* rootSaga(getState) {
  yield all([
    userSaga(),
    utilsSaga(),
    productSaga(),
    orderSaga(),
    itemSaga(),
    labtestSaga(),
  ]);
}
