export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const CLEAR = "CLEAR";
export const CLEAR_ALL_DEFAULTS = "CLEAR_ALL_DEFAULTS";
export const CLEAR_USER_DEFAULTS = "CLEAR_USER_DEFAULTS";
export const CLEAR_UTILS_DEFAULTS = "CLEAR_UTILS_DEFAULTS";
export const CLEAR_PRODUCTS_DEFAULTS = "CLEAR_PRODUCTS_DEFAULTS";
export const CLEAR_ORDER_DEFAULTS = "CLEAR_ORDER_DEFAULTS";
export const CLEAR_ITEMS_DEFAULTS = "CLEAR_ITEMS_DEFAULTS";
export const CLEAR_LABTEST_DEFAULTS = "CLEAR_LABTEST_DEFAULTS";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGIN_MODAL_REQUEST = "LOGIN_MODAL_REQUEST";
export const LOGIN_MODAL_SUCCESS = "LOGIN_MODAL_SUCCESS";
export const LOGIN_MODAL_ERROR = "LOGIN_MODAL_ERROR";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";

export const MANAGE_BRAND_REQUEST = "MANAGE_BRAND_REQUEST";
export const MANAGE_BRAND_SUCCESS = "MANAGE_BRAND_SUCCESS";
export const MANAGE_BRAND_ERROR = "MANAGE_BRAND_ERROR";

export const BRANDS_LIST_REQUEST = "BRANDS_LIST_REQUEST";
export const BRANDS_LIST_SUCCESS = "BRANDS_LIST_SUCCESS";
export const BRANDS_LIST_ERROR = "BRANDS_LIST_ERROR";

export const MANAGE_CATEGORY_REQUEST = "MANAGE_CATEGORY_REQUEST";
export const MANAGE_CATEGORY_SUCCESS = "MANAGE_CATEGORY_SUCCESS";
export const MANAGE_CATEGORY_ERROR = "MANAGE_CATEGORY_ERROR";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR";

export const MANAGE_SUBCATEGORY_REQUEST = "MANAGE_SUBCATEGORY_REQUEST";
export const MANAGE_SUBCATEGORY_SUCCESS = "MANAGE_SUBCATEGORY_SUCCESS";
export const MANAGE_SUBCATEGORY_ERROR = "MANAGE_SUBCATEGORY_ERROR";

export const SUBCATEGORY_LIST_REQUEST = "SUBCATEGORY_LIST_REQUEST";
export const SUBCATEGORY_LIST_SUCCESS = "SUBCATEGORY_LIST_SUCCESS";
export const SUBCATEGORY_LIST_ERROR = "SUBCATEGORY_LIST_ERROR";

export const MANAGE_MENU_REQUEST = "MANAGE_MENU_REQUEST";
export const MANAGE_MENU_SUCCESS = "MANAGE_MENU_SUCCESS";
export const MANAGE_MENU_ERROR = "MANAGE_MENU_ERROR";

export const MENU_LIST_REQUEST = "MENU_LIST_REQUEST";
export const MENU_LIST_SUCCESS = "MENU_LIST_SUCCESS";
export const MENU_LIST_ERROR = "MENU_LIST_ERROR";

export const MANAGE_PRODUCT_REQUEST = "MANAGE_PRODUCT_REQUEST";
export const MANAGE_PRODUCT_SUCCESS = "MANAGE_PRODUCT_SUCCESS";
export const MANAGE_PRODUCT_ERROR = "MANAGE_PRODUCT_ERROR";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_ERROR = "PRODUCT_LIST_ERROR";

export const MANAGE_PRODUCTINFO_REQUEST = "MANAGE_PRODUCTINFO_REQUEST";
export const MANAGE_PRODUCTINFO_SUCCESS = "MANAGE_PRODUCTINFO_SUCCESS";
export const MANAGE_PRODUCTINFO_ERROR = "MANAGE_PRODUCTINFO_ERROR";

export const PRODUCTINFO_REQUEST = "PRODUCTINFO_REQUEST";
export const PRODUCTINFO_SUCCESS = "PRODUCTINFO_SUCCESS";
export const PRODUCTINFO_ERROR = "PRODUCTINFO_ERROR";

export const MANAGE_PRODUCTIMAGE_REQUEST = "MANAGE_PRODUCTIMAGE_REQUEST";
export const MANAGE_PRODUCTIMAGE_SUCCESS = "MANAGE_PRODUCTIMAGE_SUCCESS";
export const MANAGE_PRODUCTIMAGE_ERROR = "MANAGE_PRODUCTIMAGE_ERROR";

export const PRODUCTIMAGE_LIST_REQUEST = "PRODUCTIMAGE_LIST_REQUEST";
export const PRODUCTIMAGE_LIST_SUCCESS = "PRODUCTIMAGE_LIST_SUCCESS";
export const PRODUCTIMAGE_LIST_ERROR = "PRODUCTIMAGE_LIST_ERROR";

export const DELETE_PRODUCTIMAGE_REQUEST = "DELETE_PRODUCTIMAGE_REQUEST";
export const DELETE_PRODUCTIMAGE_SUCCESS = "DELETE_PRODUCTIMAGE_SUCCESS";
export const DELETE_PRODUCTIMAGE_ERROR = "DELETE_PRODUCTIMAGE_ERROR";

export const UPDATE_PRODUCTSTATUS_REQUEST = "UPDATE_PRODUCTSTATUS_REQUEST";
export const UPDATE_PRODUCTSTATUS_SUCCESS = "UPDATE_PRODUCTSTATUS_SUCCESS";
export const UPDATE_PRODUCTSTATUS_ERROR = "UPDATE_PRODUCTSTATUS_ERROR";

export const UPDATE_BRANDSTATUS_REQUEST = "UPDATE_BRANDSTATUS_REQUEST";
export const UPDATE_BRANDSTATUS_SUCCESS = "UPDATE_BRANDSTATUS_SUCCESS";
export const UPDATE_BRANDSTATUS_ERROR = "UPDATE_BRANDSTATUS_ERROR";

export const UPDATE_CATEGORYSTATUS_REQUEST = "UPDATE_CATEGORYSTATUS_REQUEST";
export const UPDATE_CATEGORYSTATUS_SUCCESS = "UPDATE_CATEGORYSTATUS_SUCCESS";
export const UPDATE_CATEGORYSTATUS_ERROR = "UPDATE_CATEGORYSTATUS_ERROR";

export const UPDATE_SUBCATEGORYSTATUS_REQUEST =
  "UPDATE_SUBCATEGORYSTATUS_REQUEST";
export const UPDATE_SUBCATEGORYSTATUS_SUCCESS =
  "UPDATE_SUBCATEGORYSTATUS_SUCCESS";
export const UPDATE_SUBCATEGORYSTATUS_ERROR = "UPDATE_SUBCATEGORYSTATUS_ERROR";

export const CHECK_PAYMENT_REQUEST = "CHECK_PAYMENT_REQUEST";
export const CHECK_PAYMENT_SUCCESS = "CHECK_PAYMENT_SUCCESS";
export const CHECK_PAYMENT_ERROR = "CHECK_PAYMENT_ERROR";

export const MANAGE_PHARMACY_REQUEST = "MANAGE_PHARMACY_REQUEST";
export const MANAGE_PHARMACY_SUCCESS = "MANAGE_PHARMACY_SUCCESS";
export const MANAGE_PHARMACY_ERROR = "MANAGE_PHARMACY_ERROR";

export const MANAGE_TEMP_ORDER_REQUEST = "MANAGE_TEMP_ORDER_REQUEST";
export const MANAGE_TEMP_ORDER_SUCCESS = "MANAGE_TEMP_ORDER_SUCCESS";
export const MANAGE_TEMP_ORDER_ERROR = "MANAGE_TEMP_ORDER_ERROR";

export const ORDER_INFO_REQUEST = "ORDER_INFO_REQUEST";
export const ORDER_INFO_SUCCESS = "ORDER_INFO_SUCCESS";
export const ORDER_INFO_ERROR = "ORDER_INFO_ERROR";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_ERROR = "ORDER_LIST_ERROR";

export const PHARMACY_GATEWAY_REQUEST = "PHARMACY_GATEWAY_REQUEST";
export const PHARMACY_GATEWAY_SUCCESS = "PHARMACY_GATEWAY_SUCCESS";
export const PHARMACY_GATEWAY_ERROR = "PHARMACY_GATEWAY_ERROR";

export const PRODUCT_GATEWAY_REQUEST = "PRODUCT_GATEWAY_REQUEST";
export const PRODUCT_GATEWAY_SUCCESS = "PRODUCT_GATEWAY_SUCCESS";
export const PRODUCT_GATEWAY_ERROR = "PRODUCT_GATEWAY_ERROR";

export const ORDERSTATUS_REQUEST = "ORDERSTATUS_REQUEST";
export const ORDERSTATUS_SUCCESS = "ORDERSTATUS_SUCCESS";
export const ORDERSTATUS_ERROR = "ORDERSTATUS_ERROR";

export const DELIVER_INFO_REQUEST = "DELIVER_INFO_REQUEST";
export const DELIVER_INFO_SUCCESS = "DELIVER_INFO_SUCCESS";
export const DELIVER_INFO_ERROR = "DELIVER_INFO_ERROR";

export const PHARMACYBILL_INFO_REQUEST = "PHARMACYBILL_INFO_REQUEST";
export const PHARMACYBILL_INFO_SUCCESS = "PHARMACYBILL_INFO_SUCCESS";
export const PHARMACYBILL_INFO_ERROR = "PHARMACYBILL_INFO_ERROR";

export const MANAGE_ITEMCATEGORY_REQUEST = "MANAGE_ITEMCATEGORY_REQUEST";
export const MANAGE_ITEMCATEGORY_SUCCESS = "MANAGE_ITEMCATEGORY_SUCCESS";
export const MANAGE_ITEMCATEGORY_ERROR = "MANAGE_ITEMCATEGORY_ERROR";

export const ITEMCATEGORY_LIST_REQUEST = "ITEMCATEGORY_LIST_REQUEST";
export const ITEMCATEGORY_LIST_SUCCESS = "ITEMCATEGORY_LIST_SUCCESS";
export const ITEMCATEGORY_LIST_ERROR = "ITEMCATEGORY_LIST_ERROR";

export const UPDATE_ITEMCATEGORYSTATUS_REQUEST =
  "UPDATE_ITEMCATEGORYSTATUS_REQUEST";
export const UPDATE_ITEMCATEGORYSTATUS_SUCCESS =
  "UPDATE_ITEMCATEGORYSTATUS_SUCCESS";
export const UPDATE_ITEMCATEGORYSTATUS_ERROR =
  "UPDATE_ITEMCATEGORYSTATUS_ERROR";

export const MANAGE_ITEM_REQUEST = "MANAGE_ITEM_REQUEST";
export const MANAGE_ITEM_SUCCESS = "MANAGE_ITEM_SUCCESS";
export const MANAGE_ITEM_ERROR = "MANAGE_ITEM_ERROR";

export const ITEM_LIST_REQUEST = "ITEM_LIST_REQUEST";
export const ITEM_LIST_SUCCESS = "ITEM_LIST_SUCCESS";
export const ITEM_LIST_ERROR = "ITEM_LIST_ERROR";

export const MANAGE_ITEMINFO_REQUEST = "MANAGE_ITEMINFO_REQUEST";
export const MANAGE_ITEMINFO_SUCCESS = "MANAGE_ITEMINFO_SUCCESS";
export const MANAGE_ITEMINFO_ERROR = "MANAGE_ITEMINFO_ERROR";

export const ITEMINFO_REQUEST = "ITEMINFO_REQUEST";
export const ITEMINFO_SUCCESS = "ITEMINFO_SUCCESS";
export const ITEMINFO_ERROR = "ITEMINFO_ERROR";

export const MANAGE_ITEMIMAGE_REQUEST = "MANAGE_ITEMIMAGE_REQUEST";
export const MANAGE_ITEMIMAGE_SUCCESS = "MANAGE_ITEMIMAGE_SUCCESS";
export const MANAGE_ITEMIMAGE_ERROR = "MANAGE_ITEMIMAGE_ERROR";

export const ITEMIMAGE_LIST_REQUEST = "ITEMIMAGE_LIST_REQUEST";
export const ITEMIMAGE_LIST_SUCCESS = "ITEMIMAGE_LIST_SUCCESS";
export const ITEMIMAGE_LIST_ERROR = "ITEMIMAGE_LIST_ERROR";

export const DELETE_ITEMIMAGE_REQUEST = "DELETE_ITEMIMAGE_REQUEST";
export const DELETE_ITEMIMAGE_SUCCESS = "DELETE_ITEMIMAGE_SUCCESS";
export const DELETE_ITEMIMAGE_ERROR = "DELETE_ITEMIMAGE_ERROR";

export const UPDATE_ITEMSTATUS_REQUEST = "UPDATE_ITEMSTATUS_REQUEST";
export const UPDATE_ITEMSTATUS_SUCCESS = "UPDATE_ITEMSTATUS_SUCCESS";
export const UPDATE_ITEMSTATUS_ERROR = "UPDATE_ITEMSTATUS_ERROR";

export const MANAGE_LABTEST_REQUEST = "MANAGE_LABTEST_REQUEST";
export const MANAGE_LABTEST_SUCCESS = "MANAGE_LABTEST_SUCCESS";
export const MANAGE_LABTEST_ERROR = "MANAGE_LABTEST_ERROR";

export const LABTEST_LIST_REQUEST = "LABTEST_LIST_REQUEST";
export const LABTEST_LIST_SUCCESS = "LABTEST_LIST_SUCCESS";
export const LABTEST_LIST_ERROR = "LABTEST_LIST_ERROR";

export const LABTEST_REQUEST = "LABTEST_REQUEST";
export const LABTEST_SUCCESS = "LABTEST_SUCCESS";
export const LABTEST_ERROR = "LABTEST_ERROR";

export const MANAGE_LABTESTINFO_REQUEST = "MANAGE_LABTESTINFO_REQUEST";
export const MANAGE_LABTESTINFO_SUCCESS = "MANAGE_LABTESTINFO_SUCCESS";
export const MANAGE_LABTESTINFO_ERROR = "MANAGE_LABTESTINFO_ERROR";

export const LABTESTINFO_REQUEST = "LABTESTINFO_REQUEST";
export const LABTESTINFO_SUCCESS = "LABTESTINFO_SUCCESS";
export const LABTESTINFO_ERROR = "LABTESTINFO_ERROR";

export const MANAGE_LABTESTIMAGE_REQUEST = "MANAGE_LABTESTIMAGE_REQUEST";
export const MANAGE_LABTESTIMAGE_SUCCESS = "MANAGE_LABTESTIMAGE_SUCCESS";
export const MANAGE_LABTESTIMAGE_ERROR = "MANAGE_LABTESTIMAGE_ERROR";

export const LABTESTIMAGE_LIST_REQUEST = "LABTESTIMAGE_LIST_REQUEST";
export const LABTESTIMAGE_LIST_SUCCESS = "LABTESTIMAGE_LIST_SUCCESS";
export const LABTESTIMAGE_LIST_ERROR = "LABTESTIMAGE_LIST_ERROR";

export const DELETE_LABTESTIMAGE_REQUEST = "DELETE_LABTESTIMAGE_REQUEST";
export const DELETE_LABTESTIMAGE_SUCCESS = "DELETE_LABTESTIMAGE_SUCCESS";
export const DELETE_LABTESTIMAGE_ERROR = "DELETE_LABTESTIMAGE_ERROR";

export const UPDATE_LABTESTSTATUS_REQUEST = "UPDATE_LABTESTSTATUS_REQUEST";
export const UPDATE_LABTESTSTATUS_SUCCESS = "UPDATE_LABTESTSTATUS_SUCCESS";
export const UPDATE_LABTESTSTATUS_ERROR = "UPDATE_LABTESTSTATUS_ERROR";

export const DELETE_TESTINCLUDE_REQUEST = "DELETE_TESTINCLUDE_REQUEST";
export const DELETE_TESTINCLUDE_SUCCESS = "DELETE_TESTINCLUDE_SUCCESS";
export const DELETE_TESTINCLUDE_ERROR = "DELETE_TESTINCLUDE_ERROR";

export const AUTO_SUGGEST_REQUEST = "AUTO_SUGGEST_REQUEST";
export const AUTO_SUGGEST_SUCCESS = "AUTO_SUGGEST_SUCCESS";
export const AUTO_SUGGEST_ERROR = "AUTO_SUGGEST_ERROR";

export const MANAGE_ONLINE_ORDERSTATUS_REQUEST =
  "MANAGE_ONLINE_ORDERSTATUS_REQUEST";
export const MANAGE_ONLINE_ORDERSTATUS_SUCCESS =
  "MANAGE_ONLINE_ORDERSTATUS_SUCCESS";
export const MANAGE_ONLINE_ORDERSTATUS_ERROR =
  "MANAGE_ONLINE_ORDERSTATUS_ERROR";

export const MANAGE_ONLINE_DELIVER_REQUEST = "MANAGE_ONLINE_DELIVER_REQUEST";
export const MANAGE_ONLINE_DELIVER_SUCCESS = "MANAGE_ONLINE_DELIVER_SUCCESS";
export const MANAGE_ONLINE_DELIVER_ERROR = "MANAGE_ONLINE_DELIVER_ERROR";

export const UTILS_REQUEST = "UTILS_REQUEST";
export const UTILS_SUCCESS = "UTILS_SUCCESS";
export const UTILS_ERROR = "UTILS_ERROR";

export const MANAGE_BILL_REQUEST = "MANAGE_BILL_REQUEST";
export const MANAGE_BILL_SUCCESS = "MANAGE_BILL_SUCCESS";
export const MANAGE_BILL_ERROR = "MANAGE_BILL_ERROR";

export const BATCHNO_LIST_REQUEST = "BATCHNO_LIST_REQUEST";
export const BATCHNO_LIST_SUCCESS = "BATCHNO_LIST_SUCCESS";
export const BATCHNO_LIST_ERROR = "BATCHNO_LIST_ERROR";

export const RETURNORDERS_REQUEST = "RETURNORDERS_REQUEST";
export const RETURNORDERS_SUCCESS = "RETURNORDERS_SUCCESS";
export const RETURNORDERS_ERROR = "RETURNORDERS_ERROR";

export const RETURNORDER_INFO_REQUEST = "RETURNORDER_INFO_REQUEST";
export const RETURNORDER_INFO_SUCCESS = "RETURNORDER_INFO_SUCCESS";
export const RETURNORDER_INFO_ERROR = "RETURNORDER_INFO_ERROR";

export const RETURNORDER_ITEMS_REQUEST = "RETURNORDER_ITEMS_REQUEST";
export const RETURNORDER_ITEMS_SUCCESS = "RETURNORDER_ITEMS_SUCCESS";
export const RETURNORDER_ITEMS_ERROR = "RETURNORDER_ITEMS_ERROR";

export const MANAGE_REFUND_REQUEST = "MANAGE_REFUND_REQUEST";
export const MANAGE_REFUND_SUCCESS = "MANAGE_REFUND_SUCCESS";
export const MANAGE_REFUND_ERROR = "MANAGE_REFUND_ERROR";

export const ITEM_REQUEST = "ITEM_REQUEST";
export const ITEM_SUCCESS = "ITEM_SUCCESS";
export const ITEM_ERROR = "ITEM_ERROR";

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_ERROR = "ORDER_ERROR";

export const ORDER_ITEMS_REQUEST = "ORDER_ITEMS_REQUEST";
export const ORDER_ITEMS_SUCCESS = "ORDER_ITEMS_SUCCESS";
export const ORDER_ITEMS_ERROR = "ORDER_ITEMS_ERROR";

export const ORDER_HISTORY_REQUEST = "ORDER_HISTORY_REQUEST";
export const ORDER_HISTORY_SUCCESS = "ORDER_HISTORY_SUCCESS";
export const ORDER_HISTORY_ERROR = "ORDER_HISTORY_ERROR";

export const ORDER_IMAGES_REQUEST = "ORDER_IMAGES_REQUEST";
export const ORDER_IMAGES_SUCCESS = "ORDER_IMAGES_SUCCESS";
export const ORDER_IMAGES_ERROR = "ORDER_IMAGES_ERROR";

export const MANAGE_ORDERITEM_REQUEST = "MANAGE_ORDERITEM_REQUEST";
export const MANAGE_ORDERITEM_SUCCESS = "MANAGE_ORDERITEM_SUCCESS";
export const MANAGE_ORDERITEM_ERROR = "MANAGE_ORDERITEM_ERROR";

export const CANCEL_ITEM_REQUEST = "CANCEL_ITEM_REQUEST";
export const CANCEL_ITEM_SUCCESS = "CANCEL_ITEM_SUCCESS";
export const CANCEL_ITEM_ERROR = "CANCEL_ITEM_ERROR";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_ERROR = "CANCEL_ORDER_ERROR";

export const DELETE_ORDERITEM_REQUEST = "DELETE_ORDERITEM_REQUEST";
export const DELETE_ORDERITEM_SUCCESS = "DELETE_ORDERITEM_SUCCESS";
export const DELETE_ORDERITEM_ERROR = "DELETE_ORDERITEM_ERROR";

export const UPDATE_ORDERITEMSTATUS_REQUEST = "UPDATE_ORDERITEMSTATUS_REQUEST";
export const UPDATE_ORDERITEMSTATUS_SUCCESS = "UPDATE_ORDERITEMSTATUS_SUCCESS";
export const UPDATE_ORDERITEMSTATUS_ERROR = "UPDATE_ORDERITEMSTATUS_ERROR";

export const ORDERITEM_HISTROY_REQUEST = "ORDERITEM_HISTROY_REQUEST";
export const ORDERITEM_HISTROY_SUCCESS = "ORDERITEM_HISTROY_SUCCESS";
export const ORDERITEM_HISTROY_ERROR = "ORDERITEM_HISTROY_ERROR";

export * from "./user/action";
export * from "./utils/action";
export * from "./product/action";
export * from "./order/action";
export * from "./item/action";
export * from "./labtest/action";
