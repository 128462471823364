import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";
import {
  BRANDS_LIST_REQUEST,
  CATEGORY_LIST_REQUEST,
  CLEAR_ALL_DEFAULTS,
  MANAGE_BRAND_REQUEST,
  MANAGE_CATEGORY_REQUEST,
  MANAGE_MENU_REQUEST,
  MANAGE_SUBCATEGORY_REQUEST,
  MENU_LIST_REQUEST,
  REQUEST,
  SUBCATEGORY_LIST_REQUEST,
  UPDATE_BRANDSTATUS_REQUEST,
  UPDATE_CATEGORYSTATUS_REQUEST,
  UPDATE_SUBCATEGORYSTATUS_REQUEST,
  ITEMCATEGORY_LIST_REQUEST,
  MANAGE_ITEMCATEGORY_REQUEST,
  UPDATE_ITEMCATEGORYSTATUS_REQUEST,
  AUTO_SUGGEST_REQUEST,
  UTILS_REQUEST,
} from "../actions";
import {
  GET,
  POST,
  LIST,
  PATCH,
  DELETE,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import { clearItemDefaults } from "../item/action";
import { clearLabTestDefaults } from "../labtest/action";
import { clearOrderDefaults } from "../order/action";
import { clearProductDefaults } from "../product/action";
import { clearUserDefaults } from "../user/action";
import {
  brandsListError,
  brandsListSuccess,
  categoryListError,
  categoryListSuccess,
  clearUtilsDefaults,
  manageBrandError,
  manageBrandSuccess,
  manageCategoryError,
  manageCategorySuccess,
  manageItemCategoryError,
  manageItemCategorySuccess,
  manageMenuError,
  manageMenuSuccess,
  manageSubCategoryError,
  manageSubCategorySuccess,
  menuListError,
  menuListSuccess,
  subCategoryListError,
  subCategoryListSuccess,
  successRequest,
  updateBrandStatusError,
  updateBrandStatusSuccess,
  updateCategoryStatusError,
  updateCategoryStatusSuccess,
  updateSubCategoryStatusError,
  updateSubCategoryStatusSuccess,
  itemCategoryListError,
  itemCategoryListSuccess,
  updateItemCategoryStatusError,
  updateItemCategoryStatusSuccess,
  autoSuggestError,
  autoSuggestSuccess,
  utilsError,
  utilsSuccess,
} from "./action";
export function* watchRequest() {
  yield takeLatest(REQUEST, MyRequest);
}
function* MyRequest({ payload }) {
  yield put(successRequest(payload));
}
export function* watchManageBrand() {
  yield takeLatest(MANAGE_BRAND_REQUEST, manageBrand);
}

function* manageBrand({ payload }) {
  let url = "/utils/brand";
  var object = {};
  payload.forEach((value, key) => (object[key] = value || null));
  try {
    const response = yield call(
      object && object.brand_id ? FORMDATA_PATCH : FORMDATA_POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageBrandError(response.message));
    } else {
      yield put(manageBrandSuccess(response));
    }
  } catch (ex) {
    yield put(manageBrandError(ex));
  }
}

export function* watchBrandList() {
  yield takeLatest(BRANDS_LIST_REQUEST, brandList);
}

function* brandList({ payload }) {
  let url = "/utils/brand";
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(brandsListError(response.message));
    } else {
      yield put(brandsListSuccess(response));
    }
  } catch (ex) {
    yield put(brandsListError(ex));
  }
}

export function* watchManageCategory() {
  yield takeLatest(MANAGE_CATEGORY_REQUEST, manageCategory);
}

function* manageCategory({ payload }) {
  let url = "/utils/category";
  try {
    const response = yield call(
      payload && payload.category_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageCategoryError(response.message));
    } else {
      yield put(manageCategorySuccess(response));
    }
  } catch (ex) {
    yield put(manageCategoryError(ex));
  }
}

export function* watchCategoryList() {
  yield takeLatest(CATEGORY_LIST_REQUEST, categoryList);
}

function* categoryList({ payload }) {
  let url = "/utils/category";
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(categoryListError(response.message));
    } else {
      yield put(categoryListSuccess(response));
    }
  } catch (ex) {
    yield put(categoryListError(ex));
  }
}

export function* watchManageSubCategory() {
  yield takeLatest(MANAGE_SUBCATEGORY_REQUEST, manageSubCategory);
}

function* manageSubCategory({ payload }) {
  let url = "/utils/subcategory";
  try {
    const response = yield call(
      payload && payload.category_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageSubCategoryError(response.message));
    } else {
      yield put(manageSubCategorySuccess(response));
    }
  } catch (ex) {
    yield put(manageSubCategoryError(ex));
  }
}

export function* watchSubCategoryList() {
  yield takeLatest(SUBCATEGORY_LIST_REQUEST, subCategoryList);
}

function* subCategoryList({ payload }) {
  let url = `/utils/subcategory?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(subCategoryListError(response.message));
    } else {
      yield put(subCategoryListSuccess(response));
    }
  } catch (ex) {
    yield put(subCategoryListError(ex));
  }
}

export function* watchManageMenu() {
  yield takeLatest(MANAGE_MENU_REQUEST, manageMenu);
}

function* manageMenu({ payload }) {
  let url = "/utils/menu";
  var object = {};
  payload.forEach((value, key) => (object[key] = value || null));
  try {
    const response = yield call(
      object && object.menu_id ? FORMDATA_PATCH : FORMDATA_POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageMenuError(response.message));
    } else {
      yield put(manageMenuSuccess(response));
    }
  } catch (ex) {
    yield put(manageMenuError(ex));
  }
}

export function* watchMenuList() {
  yield takeLatest(MENU_LIST_REQUEST, menuList);
}

function* menuList({ payload }) {
  let url = "/utils/menu";
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(menuListError(response.message));
    } else {
      yield put(menuListSuccess(response));
    }
  } catch (ex) {
    yield put(menuListError(ex));
  }
}

export function* watchUpdateBrandStatus() {
  yield takeLatest(UPDATE_BRANDSTATUS_REQUEST, updateBrandStatus);
}
function* updateBrandStatus({ payload }) {
  let url = `/utils/brand`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateBrandStatusError(response.message));
    } else {
      yield put(updateBrandStatusSuccess(response));
    }
  } catch (ex) {
    yield put(updateBrandStatusError(ex));
  }
}

export function* watchUpdateCategoryStatus() {
  yield takeLatest(UPDATE_CATEGORYSTATUS_REQUEST, updateCategoryStatus);
}
function* updateCategoryStatus({ payload }) {
  let url = `/utils/category`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateCategoryStatusError(response.message));
    } else {
      yield put(updateCategoryStatusSuccess(response));
    }
  } catch (ex) {
    yield put(updateCategoryStatusError(ex));
  }
}

export function* watchUpdateSubCategoryStatus() {
  yield takeLatest(UPDATE_SUBCATEGORYSTATUS_REQUEST, updateSubCategoryStatus);
}
function* updateSubCategoryStatus({ payload }) {
  let url = `/utils/subcategory`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateSubCategoryStatusError(response.message));
    } else {
      yield put(updateSubCategoryStatusSuccess(response));
    }
  } catch (ex) {
    yield put(updateSubCategoryStatusError(ex));
  }
}
export function* watchClearDefaults() {
  yield takeLatest(CLEAR_ALL_DEFAULTS, clearAllDefaults);
}

function* clearAllDefaults({ payload }) {
  yield put(clearUserDefaults());
  yield put(clearUtilsDefaults());
  yield put(clearProductDefaults());
  yield put(clearOrderDefaults());
  yield put(clearItemDefaults());
  yield put(clearLabTestDefaults());
}

export function* watchManageItemCategory() {
  yield takeLatest(MANAGE_ITEMCATEGORY_REQUEST, manageItemCategory);
}

function* manageItemCategory({ payload }) {
  let url = "/utils/itemcategory";
  try {
    const response = yield call(
      payload && payload.category_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageItemCategoryError(response.message));
    } else {
      yield put(manageItemCategorySuccess(response));
    }
  } catch (ex) {
    yield put(manageItemCategoryError(ex));
  }
}

export function* watchItemCategoryList() {
  yield takeLatest(ITEMCATEGORY_LIST_REQUEST, itemCategoryList);
}

function* itemCategoryList({ payload }) {
  let url = "/utils/itemcategory";
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(itemCategoryListError(response.message));
    } else {
      yield put(itemCategoryListSuccess(response));
    }
  } catch (ex) {
    yield put(itemCategoryListError(ex));
  }
}

export function* watchUpdateItemCategoryStatus() {
  yield takeLatest(UPDATE_ITEMCATEGORYSTATUS_REQUEST, updateItemCategoryStatus);
}
function* updateItemCategoryStatus({ payload }) {
  let url = `/utils/itemcategory`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateItemCategoryStatusError(response.message));
    } else {
      yield put(updateItemCategoryStatusSuccess(response));
    }
  } catch (ex) {
    yield put(updateItemCategoryStatusError(ex));
  }
}

export function* watchAutoSuggest() {
  yield takeLatest(AUTO_SUGGEST_REQUEST, autoSuggest);
}
function* autoSuggest({ payload }) {
  let url = `/utils/autosuggest?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(autoSuggestError(response.message));
    } else {
      yield put(autoSuggestSuccess(response, payload.key));
    }
  } catch (ex) {
    yield put(autoSuggestError(ex));
  }
}

export function* watchUtils() {
  yield takeLatest(UTILS_REQUEST, utils);
}
function* utils({ payload }) {
  let url = `/utils`;
  try {
    const response = yield call(POST, url, payload);
    if (response && response.is_error) {
      yield put(utilsError(response.message));
    } else {
      yield put(utilsSuccess(response, payload.key));
    }
  } catch (ex) {
    yield put(utilsError(ex));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequest),
    fork(watchManageBrand),
    fork(watchBrandList),
    fork(watchManageCategory),
    fork(watchCategoryList),
    fork(watchManageSubCategory),
    fork(watchSubCategoryList),
    fork(watchManageMenu),
    fork(watchMenuList),
    fork(watchUpdateBrandStatus),
    fork(watchUpdateCategoryStatus),
    fork(watchUpdateSubCategoryStatus),
    fork(watchManageItemCategory),
    fork(watchItemCategoryList),
    fork(watchUpdateItemCategoryStatus),
    fork(watchAutoSuggest),
    fork(watchUtils),
  ]);
}
