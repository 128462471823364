import {
  GET,
  POST,
  LIST,
  PATCH,
  DELETE,
  FORMDATA_POST,
  FORMDATA_PATCH,
} from "../api";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  DELETE_PRODUCTIMAGE_REQUEST,
  MANAGE_PRODUCTIMAGE_REQUEST,
  MANAGE_PRODUCTINFO_REQUEST,
  MANAGE_PRODUCT_REQUEST,
  PRODUCTIMAGE_LIST_REQUEST,
  PRODUCTINFO_REQUEST,
  PRODUCT_LIST_REQUEST,
  UPDATE_PRODUCTSTATUS_REQUEST,
} from "../actions";
import {
  deleteProductImageError,
  deleteProductImageSuccess,
  manageProductError,
  manageProductImageError,
  manageProductImageSuccess,
  manageProductInfoError,
  manageProductInfoSuccess,
  manageProductSuccess,
  productImageListError,
  productImageListSuccess,
  productInfoError,
  productInfoSuccess,
  productsListError,
  productsListSuccess,
  updateProductStatusError,
  updateProductStatusSuccess,
} from "./action";
import { objectToQueryString } from "../../helpers/utils";
export function* watchManageProduct() {
  yield takeLatest(MANAGE_PRODUCT_REQUEST, manageProduct);
}
function* manageProduct({ payload }) {
  let url = "/products";
  try {
    const response = yield call(
      payload && payload.product_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageProductError(response.message));
    } else {
      yield put(manageProductSuccess(response));
    }
  } catch (ex) {
    yield put(manageProductError(ex));
  }
}

export function* watchProductList() {
  yield takeLatest(PRODUCT_LIST_REQUEST, productList);
}
function* productList({ payload }) {
  let url = `/products?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(productsListError(response.message));
    } else {
      yield put(productsListSuccess(response));
    }
  } catch (ex) {
    yield put(productsListError(ex));
  }
}

export function* watchManageProductInfo() {
  yield takeLatest(MANAGE_PRODUCTINFO_REQUEST, manageProductInfo);
}
function* manageProductInfo({ payload }) {
  let url = `/products/productinfo`;
  try {
    const response = yield call(
      payload && payload.info_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageProductInfoError(response.message));
    } else {
      yield put(manageProductInfoSuccess(response));
    }
  } catch (ex) {
    yield put(manageProductInfoError(ex));
  }
}

export function* watchProductInfo() {
  yield takeLatest(PRODUCTINFO_REQUEST, productInfo);
}
function* productInfo({ payload }) {
  let url = `/products/productinfo?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(productInfoError(response.message));
    } else {
      yield put(productInfoSuccess(response));
    }
  } catch (ex) {
    yield put(productInfoError(ex));
  }
}

export function* watchManageProductImage() {
  yield takeLatest(MANAGE_PRODUCTIMAGE_REQUEST, manageProductImage);
}
function* manageProductImage({ payload }) {
  let url = `/products/productimage`;
  try {
    const response = yield call(
      payload && payload.image_id ? PATCH : POST,
      url,
      payload
    );
    if (response && response.is_error) {
      yield put(manageProductImageError(response.message));
    } else {
      yield put(manageProductImageSuccess(response));
    }
  } catch (ex) {
    yield put(manageProductImageError(ex));
  }
}
export function* watchProductImageList() {
  yield takeLatest(PRODUCTIMAGE_LIST_REQUEST, productImageList);
}
function* productImageList({ payload }) {
  let url = `/products/productimage?${objectToQueryString(payload)}`;
  try {
    const response = yield call(LIST, url, payload);
    if (response && response.is_error) {
      yield put(productImageListError(response.message));
    } else {
      yield put(productImageListSuccess(response));
    }
  } catch (ex) {
    yield put(productImageListError(ex));
  }
}

export function* watchDeleteProductImage() {
  yield takeLatest(DELETE_PRODUCTIMAGE_REQUEST, deleteProductImage);
}
function* deleteProductImage({ payload }) {
  let url = `/products/productimage?${objectToQueryString(payload)}`;
  try {
    const response = yield call(DELETE, url, payload);
    if (response && response.is_error) {
      yield put(deleteProductImageError(response.message));
    } else {
      yield put(deleteProductImageSuccess(response));
    }
  } catch (ex) {
    yield put(deleteProductImageError(ex));
  }
}

export function* watchUpdateProductStatus() {
  yield takeLatest(UPDATE_PRODUCTSTATUS_REQUEST, updateProductStatus);
}
function* updateProductStatus({ payload }) {
  let url = `/products`;
  try {
    const response = yield call(PATCH, url, payload);
    if (response && response.is_error) {
      yield put(updateProductStatusError(response.message));
    } else {
      yield put(updateProductStatusSuccess(response));
    }
  } catch (ex) {
    yield put(updateProductStatusError(ex));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchManageProduct),
    fork(watchProductList),
    fork(watchManageProductInfo),
    fork(watchProductInfo),
    fork(watchManageProductImage),
    fork(watchProductImageList),
    fork(watchDeleteProductImage),
    fork(watchUpdateProductStatus),
  ]);
}
