import {
  BRANDS_LIST_ERROR,
  BRANDS_LIST_REQUEST,
  BRANDS_LIST_SUCCESS,
  CATEGORY_LIST_ERROR,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CLEAR,
  CLEAR_ALL_DEFAULTS,
  CLEAR_SETUP_DEFAULTS,
  CLEAR_UTILS_DEFAULTS,
  MANAGE_BRAND_ERROR,
  MANAGE_BRAND_REQUEST,
  MANAGE_BRAND_SUCCESS,
  MANAGE_CATEGORY_ERROR,
  MANAGE_CATEGORY_REQUEST,
  MANAGE_CATEGORY_SUCCESS,
  MANAGE_MENU_ERROR,
  MANAGE_MENU_REQUEST,
  MANAGE_MENU_SUCCESS,
  MANAGE_SUBCATEGORY_ERROR,
  MANAGE_SUBCATEGORY_REQUEST,
  MANAGE_SUBCATEGORY_SUCCESS,
  MENU_LIST_ERROR,
  MENU_LIST_REQUEST,
  MENU_LIST_SUCCESS,
  REQUEST,
  SUBCATEGORY_LIST_ERROR,
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUCCESS,
  UPDATE_BRANDSTATUS_ERROR,
  UPDATE_BRANDSTATUS_REQUEST,
  UPDATE_BRANDSTATUS_SUCCESS,
  UPDATE_CATEGORYSTATUS_REQUEST,
  UPDATE_CATEGORYSTATUS_SUCCESS,
  UPDATE_SUBCATEGORYSTATUS_ERROR,
  UPDATE_SUBCATEGORYSTATUS_REQUEST,
  UPDATE_SUBCATEGORYSTATUS_SUCCESS,
  ITEMCATEGORY_LIST_ERROR,
  ITEMCATEGORY_LIST_REQUEST,
  ITEMCATEGORY_LIST_SUCCESS,
  MANAGE_ITEMCATEGORY_ERROR,
  MANAGE_ITEMCATEGORY_REQUEST,
  MANAGE_ITEMCATEGORY_SUCCESS,
  UPDATE_CATEGORYSTATUS_ERROR,
  UPDATE_ITEMCATEGORYSTATUS_REQUEST,
  UPDATE_ITEMCATEGORYSTATUS_SUCCESS,
  UPDATE_ITEMCATEGORYSTATUS_ERROR,
  AUTO_SUGGEST_REQUEST,
  AUTO_SUGGEST_SUCCESS,
  AUTO_SUGGEST_ERROR,
  UTILS_REQUEST,
  UTILS_SUCCESS,
  UTILS_ERROR,
} from "../actions";
export const clearStatusDefault = (message) => ({
  type: CLEAR_ALL_DEFAULTS,
  payload: message,
});
export const clearUtilsDefaults = (message) => ({
  type: CLEAR_UTILS_DEFAULTS,
  payload: message,
});
export const setRequest = (request) => ({
  type: REQUEST,
  payload: request,
});
export const successRequest = (request) => ({
  type: SUCCESS,
  payload: request,
});

export const clearRequest = (request) => ({
  type: CLEAR,
  payload: request,
});

export const manageBrandRequest = (request) => ({
  payload: request,
  type: MANAGE_BRAND_REQUEST,
});
export const manageBrandSuccess = (response) => ({
  payload: response,
  type: MANAGE_BRAND_SUCCESS,
});

export const manageBrandError = (message) => ({
  payload: message,
  type: MANAGE_BRAND_ERROR,
});

export const brandsListRequest = (request) => ({
  payload: request,
  type: BRANDS_LIST_REQUEST,
});
export const brandsListSuccess = (response) => ({
  payload: response,
  type: BRANDS_LIST_SUCCESS,
});

export const brandsListError = (message) => ({
  payload: message,
  type: BRANDS_LIST_ERROR,
});

export const manageCategoryRequest = (request) => ({
  payload: request,
  type: MANAGE_CATEGORY_REQUEST,
});
export const manageCategorySuccess = (response) => ({
  payload: response,
  type: MANAGE_CATEGORY_SUCCESS,
});

export const manageCategoryError = (message) => ({
  payload: message,
  type: MANAGE_CATEGORY_ERROR,
});

export const categoryListRequest = (request) => ({
  payload: request,
  type: CATEGORY_LIST_REQUEST,
});
export const categoryListSuccess = (response) => ({
  payload: response,
  type: CATEGORY_LIST_SUCCESS,
});

export const categoryListError = (message) => ({
  payload: message,
  type: CATEGORY_LIST_ERROR,
});

export const manageSubCategoryRequest = (request) => ({
  payload: request,
  type: MANAGE_SUBCATEGORY_REQUEST,
});
export const manageSubCategorySuccess = (response) => ({
  payload: response,
  type: MANAGE_SUBCATEGORY_SUCCESS,
});

export const manageSubCategoryError = (message) => ({
  payload: message,
  type: MANAGE_SUBCATEGORY_ERROR,
});

export const subCategoryListRequest = (request) => ({
  payload: request,
  type: SUBCATEGORY_LIST_REQUEST,
});
export const subCategoryListSuccess = (response) => ({
  payload: response,
  type: SUBCATEGORY_LIST_SUCCESS,
});

export const subCategoryListError = (message) => ({
  payload: message,
  type: SUBCATEGORY_LIST_ERROR,
});

export const manageMenuRequest = (request) => ({
  payload: request,
  type: MANAGE_MENU_REQUEST,
});
export const manageMenuSuccess = (response) => ({
  payload: response,
  type: MANAGE_MENU_SUCCESS,
});

export const manageMenuError = (message) => ({
  payload: message,
  type: MANAGE_MENU_ERROR,
});

export const menuListRequest = (request) => ({
  payload: request,
  type: MENU_LIST_REQUEST,
});
export const menuListSuccess = (response) => ({
  payload: response,
  type: MENU_LIST_SUCCESS,
});

export const menuListError = (message) => ({
  payload: message,
  type: MENU_LIST_ERROR,
});

export const updateBrandStatusRequest = (request) => ({
  payload: request,
  type: UPDATE_BRANDSTATUS_REQUEST,
});
export const updateBrandStatusSuccess = (response) => ({
  payload: response,
  type: UPDATE_BRANDSTATUS_SUCCESS,
});

export const updateBrandStatusError = (message) => ({
  payload: message,
  type: UPDATE_BRANDSTATUS_ERROR,
});

export const updateCategoryStatusRequest = (request) => ({
  payload: request,
  type: UPDATE_CATEGORYSTATUS_REQUEST,
});
export const updateCategoryStatusSuccess = (response) => ({
  payload: response,
  type: UPDATE_CATEGORYSTATUS_SUCCESS,
});

export const updateCategoryStatusError = (message) => ({
  payload: message,
  type: UPDATE_CATEGORYSTATUS_ERROR,
});

export const updateSubCategoryStatusRequest = (request) => ({
  payload: request,
  type: UPDATE_SUBCATEGORYSTATUS_REQUEST,
});
export const updateSubCategoryStatusSuccess = (response) => ({
  payload: response,
  type: UPDATE_SUBCATEGORYSTATUS_SUCCESS,
});

export const updateSubCategoryStatusError = (message) => ({
  payload: message,
  type: UPDATE_SUBCATEGORYSTATUS_ERROR,
});

export const manageItemCategoryRequest = (request) => ({
  payload: request,
  type: MANAGE_ITEMCATEGORY_REQUEST,
});
export const manageItemCategorySuccess = (response) => ({
  payload: response,
  type: MANAGE_ITEMCATEGORY_SUCCESS,
});

export const manageItemCategoryError = (message) => ({
  payload: message,
  type: MANAGE_ITEMCATEGORY_ERROR,
});

export const itemCategoryListRequest = (request) => ({
  payload: request,
  type: ITEMCATEGORY_LIST_REQUEST,
});
export const itemCategoryListSuccess = (response) => ({
  payload: response,
  type: ITEMCATEGORY_LIST_SUCCESS,
});

export const itemCategoryListError = (message) => ({
  payload: message,
  type: ITEMCATEGORY_LIST_ERROR,
});

export const updateItemCategoryStatusRequest = (request) => ({
  payload: request,
  type: UPDATE_ITEMCATEGORYSTATUS_REQUEST,
});
export const updateItemCategoryStatusSuccess = (response) => ({
  payload: response,
  type: UPDATE_ITEMCATEGORYSTATUS_SUCCESS,
});

export const updateItemCategoryStatusError = (message) => ({
  payload: message,
  type: UPDATE_ITEMCATEGORYSTATUS_ERROR,
});

export const autoSuggestRequest = (request) => ({
  payload: request,
  type: AUTO_SUGGEST_REQUEST,
});
export const autoSuggestSuccess = (response, key) => ({
  payload: response,
  type: AUTO_SUGGEST_SUCCESS,
  key: key,
});

export const autoSuggestError = (message) => ({
  payload: message,
  type: AUTO_SUGGEST_ERROR,
});

export const utilsRequest = (request) => ({
  payload: request,
  type: UTILS_REQUEST,
});
export const utilsSuccess = (response, key) => ({
  payload: response,
  type: UTILS_SUCCESS,
  key: key,
});

export const utilsError = (message) => ({
  payload: message,
  type: UTILS_ERROR,
});
