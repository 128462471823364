import {
  CLEAR_ITEMS_DEFAULTS,
  DELETE_ITEMIMAGE_ERROR,
  DELETE_ITEMIMAGE_REQUEST,
  DELETE_ITEMIMAGE_SUCCESS,
  ITEM_LIST_ERROR,
  ITEM_LIST_REQUEST,
  ITEM_LIST_SUCCESS,
  MANAGE_ITEM_ERROR,
  MANAGE_ITEM_REQUEST,
  MANAGE_ITEM_SUCCESS,
  MANAGE_ITEMIMAGE_ERROR,
  MANAGE_ITEMIMAGE_REQUEST,
  MANAGE_ITEMIMAGE_SUCCESS,
  MANAGE_ITEMINFO_ERROR,
  MANAGE_ITEMINFO_REQUEST,
  MANAGE_ITEMINFO_SUCCESS,
  ITEMIMAGE_LIST_ERROR,
  ITEMIMAGE_LIST_REQUEST,
  ITEMIMAGE_LIST_SUCCESS,
  ITEMINFO_ERROR,
  ITEMINFO_REQUEST,
  ITEMINFO_SUCCESS,
  UPDATE_ITEMSTATUS_ERROR,
  UPDATE_ITEMSTATUS_REQUEST,
  UPDATE_ITEMSTATUS_SUCCESS,
  ITEM_REQUEST,
  ITEM_SUCCESS,
  ITEM_ERROR,
} from "../actions";

const INIT = {
  item_loading: false,
  item_action: null,
  item_success: null,
  item_error: null,
  item_list: [],
  item_info: null,
  item_image_list: [],
  item: null,
};

export default (state = INIT, action) => {
  switch (action.type) {
    case CLEAR_ITEMS_DEFAULTS:
      return {
        ...state,
        item_loading: false,
        item_success: null,
        item_error: null,
        item_action: null,
      };
    case MANAGE_ITEM_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
      };
    case MANAGE_ITEM_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_success: action.payload,
      };
    case MANAGE_ITEM_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };
    case ITEM_LIST_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
        item_list: [],
      };
    case ITEM_LIST_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_list: action.payload,
      };
    case ITEM_LIST_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };
    case MANAGE_ITEMINFO_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
      };
    case MANAGE_ITEMINFO_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_success: action.payload,
      };
    case MANAGE_ITEMINFO_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };
    case ITEMINFO_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
        item_info: null,
      };
    case ITEMINFO_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_info: action.payload,
      };
    case ITEMINFO_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };

    case MANAGE_ITEMIMAGE_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
      };
    case MANAGE_ITEMIMAGE_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_success: action.payload,
      };
    case MANAGE_ITEMIMAGE_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };

    case ITEMIMAGE_LIST_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
        item_image_list: [],
      };
    case ITEMIMAGE_LIST_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_image_list: action.payload,
      };
    case ITEMIMAGE_LIST_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };

    case DELETE_ITEMIMAGE_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
      };
    case DELETE_ITEMIMAGE_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_success: action.payload,
      };
    case DELETE_ITEMIMAGE_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };

    case UPDATE_ITEMSTATUS_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
      };
    case UPDATE_ITEMSTATUS_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item_success: action.payload,
      };
    case UPDATE_ITEMSTATUS_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };
    case ITEM_REQUEST:
      return {
        ...state,
        item_loading: true,
        item_success: null,
        item_error: null,
        item_action: action.type,
        item: null,
        item_info: null,
      };
    case ITEM_SUCCESS:
      return {
        ...state,
        item_loading: false,
        item: action.payload,
      };
    case ITEM_ERROR:
      return {
        ...state,
        item_loading: false,
        item_error: action.payload,
      };
    default:
      return { ...state };
  }
};
