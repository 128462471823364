import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { GET, POST, LIST, PATCH, DELETE } from "../api";
import {
  ADDRESS_LIST_REQUEST,
  DELETE_ADDERSS_REQUEST,
  LOGIN_REQUEST,
  MANAGE_USER_ADDRESS_REQUEST,
  REQUEST,
  SIGNUP_REQUEST,
  USER_DETAILS_REQUEST,
} from "../actions";
import {
  addressListError,
  addressListSuccess,
  deleteAddressError,
  deleteAddressSuccess,
  loginError,
  loginSuccess,
  manageUserAddressError,
  manageUserAddressSuccess,
  signupError,
  signupSuccess,
  userDetailsError,
  userDetailsSuccess,
} from "./action";
export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, login);
}
const LoginAsync = async (url, request) => {
  const axios = require("axios");

  return await axios
    .post(url, request)
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};

function* login({ payload }) {
  const { request, history } = payload;
  let url = "/user/token";
  try {
    const response = yield call(LoginAsync, url, request);
    if (response && response.is_error) {
      yield put(loginError(response.message));
    } else {
      yield put(loginSuccess(response));
      localStorage.setItem("token", response.accessToken);
      if (history) {
        if (history.location.state) {
          if (history.location.state.from) {
            window.location.href = history.location.state.from.pathname;
          } else {
            window.location.href = "/orders";
          }
        } else {
          window.location.href = "/orders";
        }
      } else {
        window.location.reload(true);
      }
    }
  } catch (ex) {
    yield put(loginError(ex));
  }
}

export function* watchUserDetails() {
  yield takeLatest(USER_DETAILS_REQUEST, userDetails);
}

function* userDetails({ payload }) {
  let url = "/user";
  try {
    const response = yield call(GET, url, payload);
    if (response && response.is_error) {
      yield put(userDetailsError(response.message));
    } else {
      yield put(userDetailsSuccess(response));
    }
  } catch (ex) {
    yield put(userDetailsError(ex));
  }
}

export default function* rootSaga() {
  yield all([fork(watchLogin), fork(watchUserDetails)]);
}
