import {
  CLEAR_USER_DEFAULTS,
  LOGIN_ERROR,
  LOGIN_MODAL_ERROR,
  LOGIN_MODAL_REQUEST,
  LOGIN_MODAL_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_MODAL_ERROR,
  SIGNUP_MODAL_REQUEST,
  SIGNUP_MODAL_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  USER_DETAILS_ERROR,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
} from "../actions";

const INIT = {
  user_loading: false,
  user_success: null,
  user_error: null,
  user_action: null,
  token: null,
  user: null,
  login_modal: false,
  signup_modal: false,
  address_list: [],
  request: {},
};

export default (state = INIT, action) => {
  switch (action.type) {
    case CLEAR_USER_DEFAULTS:
      return {
        ...state,
        user_loading: false,
        user_success: null,
        user_error: null,
        user_action: null,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_success: null,
        user_error: null,
        token: null,
        user_action: action.type,
      };
    case LOGIN_SUCCESS:
      return { ...state, user_loading: false, token: action.payload };
    case LOGIN_ERROR:
      return { ...state, user_loading: false, user_error: action.payload };

    case USER_DETAILS_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_success: null,
        user_error: null,
        user_action: action.type,
        user: null,
      };
    case USER_DETAILS_SUCCESS:
      return { ...state, user_loading: false, user: action.payload };
    case USER_DETAILS_ERROR:
      return { ...state, user_loading: false, user_error: action.payload };

    case LOGIN_MODAL_REQUEST:
      return {
        ...state,
        login_modal: true,
        user_success: null,
        user_error: null,
        user_action: action.type,
        user: null,
      };
    case LOGIN_MODAL_SUCCESS:
      return { ...state, login_modal: false };
    case LOGIN_MODAL_ERROR:
      return { ...state, login_modal: false };

    default:
      return { ...state };
  }
};
