import axios from "axios";
import { Multipartheader, header, token } from "../helpers/utils";

export const POST = async (url, request) => {
  return await axios
    .post(url, request, header())
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};
export const FORMDATA_POST = async (url, request) => {
  return await axios
    .post(url, request, Multipartheader())
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};

export const FORMDATA_PATCH = async (url, request) => {
  return await axios
    .patch(url, request, Multipartheader())
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};

export const GET = async (url) => {
  return await axios
    .get(url, header())
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};

export const LIST = async (url, request) => {
  return await axios
    .get(url, header())
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};

export const PATCH = async (url, request) => {
  return await axios
    .patch(url, request, header())
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};

export const DELETE = async (url, request) => {
  return await axios
    .delete(url, {
      data: request,
      headers: {
        Authorization: "Bearer " + token(),
      },
    })
    .then((x) => x.data)
    .catch((ex) => {
      if (ex) {
        ex["is_error"] = true;
        if (ex.response) {
          if (ex.response.data) {
            const { error } = ex.response.data;
            if (error) {
              ex["message"] = error.message;
            }
          }
        }
      }
      return ex;
    });
};
