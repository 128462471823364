import {
  AUTO_SUGGEST_ERROR,
  AUTO_SUGGEST_REQUEST,
  AUTO_SUGGEST_SUCCESS,
  BRANDS_LIST_ERROR,
  BRANDS_LIST_REQUEST,
  BRANDS_LIST_SUCCESS,
  CATEGORY_LIST_ERROR,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CLEAR,
  CLEAR_UTILS_DEFAULTS,
  ITEMCATEGORY_LIST_ERROR,
  ITEMCATEGORY_LIST_REQUEST,
  ITEMCATEGORY_LIST_SUCCESS,
  MANAGE_BRAND_ERROR,
  MANAGE_BRAND_REQUEST,
  MANAGE_BRAND_SUCCESS,
  MANAGE_CATEGORY_ERROR,
  MANAGE_CATEGORY_REQUEST,
  MANAGE_CATEGORY_SUCCESS,
  MANAGE_ITEMCATEGORY_ERROR,
  MANAGE_ITEMCATEGORY_REQUEST,
  MANAGE_ITEMCATEGORY_SUCCESS,
  MANAGE_MENU_ERROR,
  MANAGE_MENU_REQUEST,
  MANAGE_MENU_SUCCESS,
  MANAGE_SUBCATEGORY_ERROR,
  MANAGE_SUBCATEGORY_REQUEST,
  MANAGE_SUBCATEGORY_SUCCESS,
  MENU_LIST_ERROR,
  MENU_LIST_REQUEST,
  MENU_LIST_SUCCESS,
  REQUEST,
  SUBCATEGORY_LIST_ERROR,
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUCCESS,
  UPDATE_BRANDSTATUS_ERROR,
  UPDATE_BRANDSTATUS_REQUEST,
  UPDATE_BRANDSTATUS_SUCCESS,
  UPDATE_CATEGORYSTATUS_ERROR,
  UPDATE_CATEGORYSTATUS_REQUEST,
  UPDATE_CATEGORYSTATUS_SUCCESS,
  UPDATE_ITEMCATEGORYSTATUS_ERROR,
  UPDATE_ITEMCATEGORYSTATUS_REQUEST,
  UPDATE_ITEMCATEGORYSTATUS_SUCCESS,
  UPDATE_SUBCATEGORYSTATUS_ERROR,
  UPDATE_SUBCATEGORYSTATUS_REQUEST,
  UPDATE_SUBCATEGORYSTATUS_SUCCESS,
  UTILS_ERROR,
  UTILS_REQUEST,
  UTILS_SUCCESS,
} from "../actions";

const INIT = {
  utils_loading: false,
  utils_action: null,
  utils_success: null,
  utils_error: null,
  brands_list: [],
  category_list: [],
  sub_category_list: [],
  request: {},
  menu_list: [],
  item_category_list: [],
  auto_labtest: [],
  utils_list: [],
};

export default (state = INIT, action) => {
  switch (action.type) {
    case CLEAR_UTILS_DEFAULTS:
      return {
        ...state,
        utils_loading: false,
        utils_success: null,
        utils_error: null,
        utils_action: null,
      };
    case REQUEST:
      return {
        ...state,
        utils_loading: false,
        utils_success: null,
        utils_error: null,
        utils_action: null,
        request: { ...state.request },
      };
    case SUCCESS:
      return {
        ...state,
        request: action.payload,
      };
    case CLEAR:
      return {
        ...state,
        request: {},
      };

    case MANAGE_BRAND_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case MANAGE_BRAND_SUCCESS:
      return { ...state, utils_loading: false, utils_success: action.payload };
    case MANAGE_BRAND_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };
    case BRANDS_LIST_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
        brands_list: [],
      };
    case BRANDS_LIST_SUCCESS:
      return { ...state, utils_loading: false, brands_list: action.payload };
    case BRANDS_LIST_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };

    case MANAGE_CATEGORY_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case MANAGE_CATEGORY_SUCCESS:
      return { ...state, utils_loading: false, utils_success: action.payload };
    case MANAGE_CATEGORY_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };
    case CATEGORY_LIST_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
        category_list: [],
        sub_category_list: [],
      };
    case CATEGORY_LIST_SUCCESS:
      return { ...state, utils_loading: false, category_list: action.payload };
    case CATEGORY_LIST_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };

    case MANAGE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case MANAGE_SUBCATEGORY_SUCCESS:
      return { ...state, utils_loading: false, utils_success: action.payload };
    case MANAGE_SUBCATEGORY_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };
    case SUBCATEGORY_LIST_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
        sub_category_list: [],
      };
    case SUBCATEGORY_LIST_SUCCESS:
      return {
        ...state,
        utils_loading: false,
        sub_category_list: action.payload,
      };
    case SUBCATEGORY_LIST_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };

    case MANAGE_MENU_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case MANAGE_MENU_SUCCESS:
      return { ...state, utils_loading: false, utils_success: action.payload };
    case MANAGE_MENU_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };
    case MENU_LIST_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
        menu_list: [],
      };
    case MENU_LIST_SUCCESS:
      return {
        ...state,
        utils_loading: false,
        menu_list: action.payload,
      };
    case MENU_LIST_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };

    case UPDATE_BRANDSTATUS_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case UPDATE_BRANDSTATUS_SUCCESS:
      return {
        ...state,
        utils_loading: false,
        utils_success: action.payload,
      };
    case UPDATE_BRANDSTATUS_ERROR:
      return {
        ...state,
        utils_loading: false,
        utils_error: action.payload,
      };

    case UPDATE_CATEGORYSTATUS_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case UPDATE_CATEGORYSTATUS_SUCCESS:
      return {
        ...state,
        utils_loading: false,
        utils_success: action.payload,
      };
    case UPDATE_CATEGORYSTATUS_ERROR:
      return {
        ...state,
        utils_loading: false,
        utils_error: action.payload,
      };

    case UPDATE_SUBCATEGORYSTATUS_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case UPDATE_SUBCATEGORYSTATUS_SUCCESS:
      return {
        ...state,
        utils_loading: false,
        utils_success: action.payload,
      };
    case UPDATE_SUBCATEGORYSTATUS_ERROR:
      return {
        ...state,
        utils_loading: false,
        utils_error: action.payload,
      };

    case MANAGE_ITEMCATEGORY_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case MANAGE_ITEMCATEGORY_SUCCESS:
      return { ...state, utils_loading: false, utils_success: action.payload };
    case MANAGE_ITEMCATEGORY_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };
    case ITEMCATEGORY_LIST_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
        item_category_list: [],
      };
    case ITEMCATEGORY_LIST_SUCCESS:
      return {
        ...state,
        utils_loading: false,
        item_category_list: action.payload,
      };
    case ITEMCATEGORY_LIST_ERROR:
      return { ...state, utils_loading: false, utils_error: action.payload };

    case UPDATE_ITEMCATEGORYSTATUS_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case UPDATE_ITEMCATEGORYSTATUS_SUCCESS:
      return {
        ...state,
        utils_loading: false,
        utils_success: action.payload,
      };
    case UPDATE_ITEMCATEGORYSTATUS_ERROR:
      return {
        ...state,
        utils_loading: false,
        utils_error: action.payload,
      };

    case AUTO_SUGGEST_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
      };
    case AUTO_SUGGEST_SUCCESS:
      if (action.key == "auto_labtest") {
        return { ...state, auto_labtest: action.payload, utils_loading: false };
      } else if (action.key == "auto_items") {
        return { ...state, auto_items: action.payload, utils_loading: false };
      } else
        return {
          ...state,
          utils_loading: false,
        };
    case AUTO_SUGGEST_ERROR:
      return {
        ...state,
        utils_loading: false,
        utils_error: action.payload,
      };

    case UTILS_REQUEST:
      return {
        ...state,
        utils_loading: true,
        utils_success: null,
        utils_error: null,
        utils_action: action.type,
        utils_list: [],
      };
    case UTILS_SUCCESS:
      return { ...state, utils_list: action.payload, utils_loading: false };

    case UTILS_ERROR:
      return {
        ...state,
        utils_loading: false,
        utils_error: action.payload,
      };

    default:
      return { ...state };
  }
};
