import React, { Suspense } from "react";
import "antd/dist/antd.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { setRequest, userDetailsRequest } from "./redux/actions";
import { CheckAuth } from "./helpers/utils";
import Signin from "./views/user/signin";
import Error from "./views/error";
import Login from "./views/login";

// const ProductsView = React.lazy(() => import("./views/products"));
const UtilsView = React.lazy(() => import("./views/utils"));
const OrderView = React.lazy(() => import("./views/order"));
const ItemView = React.lazy(() => import("./views/items"));
const LabTestView = React.lazy(() => import("./views/labtest"));

const App = (props) => {
  React.useEffect(() => {
    if (!CheckAuth()) {
      // props.setRequest({ ...props.request, login_modal: true });
    } else {
      props.userDetailsAsync();
    }
  }, []);

  return (
    <>
      <div className="App">
        <Signin />
        <Router>
          <Suspense fallback={<div className="loading" />}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />

              {/* <AuthRoute path="/products" component={ProductsView} /> */}
              <AuthRoute path="/utils" component={UtilsView} />
              <AuthRoute path="/orders" component={OrderView} />
              <AuthRoute path="/items" component={ItemView} />
              <AuthRoute path="/labtest" component={LabTestView} />

              <Route to="/error" component={Error} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    </>
  );
};
const mapStateToProps = ({ utils_reducer }) => {
  const { request } = utils_reducer;
  return { request };
};

const mapDispatchToProps = {
  setRequest: setRequest,
  userDetailsAsync: userDetailsRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        CheckAuth() == true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
